import React from 'react';
import { Tab, Segment, Button, Modal } from 'semantic-ui-react';
import { Dashboard } from '../Dashboard/Dashboard';
import { Events } from '../Events/Events';
import { Reports } from '../Reports/Reports';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';
import BiReporting from '../BiReporting';

const dashboardPane = {
  menuItem: {
    key: 'dashboard',
    icon: 'grid layout',
    content: 'Dashboard',
    style: { color: 'white' },
  },
  render: (props) => (
    props.panes !== undefined && props.profile !== undefined ? (
      props.panes.forEach( (pane) => {

        pane.menuItem.className = `menu${pane.menuItem.content}${props.profile.role.name}`;
      })
     ) : null,
    <Tab.Pane
      attached={true}
      className="Dashboard"
      style={{
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0px',
        width: '100%',
      }}
    >
      <Dashboard
        render={({ substance, gender, referral }) => (
          <div>
            {/* <p>{content}</p> */}
            <Segment basic compact style={{ display: 'inline-flex', marginLeft: 'auto', marginRight: 'auto' }}>
              {gender}
              {substance}
            </Segment>
            <br />
            <React.Fragment>{referral}</React.Fragment>
          </div>
        )}
        profile={props.profile}
        width={props.width}
      />
    </Tab.Pane>
  ),
};

const eventsPane = {
  menuItem: {
    key: 'events',
    icon: 'medkit',
    content: 'Events',
    style: { color: 'white' },
  },
  render: props => (
    props.panes !== undefined && props.profile !== undefined ? (
      props.panes.forEach( (pane) => {
        pane.menuItem.className = `menu${pane.menuItem.content}${props.profile.role.name}`;
      })
     ) : null,
    <Tab.Pane
      attached={true}
      className="UIMain"
      style={{
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0px',
        width: '100%',
      }}
    >
    <BaseObjectConsumer>
    {({getRecords}) =>
      <Events
        render={({
          baseRecord,
          modal,
          leftFooter,
          baseRecords,
        }) => (
          <div>
            {props.activeComponent === 'base'
              ? baseRecord
              : props.activeComponent === 'baseRecord'
                ? baseRecords
                : props.activeComponent === 'community'
                ? baseRecord
                : props.activeComponent === 'eventTypeCheck' ?
                  <React.Fragment>
                    <Modal
                      open={true}
                      centered
                      style={{textAlign: 'center'}}
                    >
                      <Modal.Header textAlign="middle">
                        Would you like to load the Community or Participant interface?
                      </Modal.Header>
                      <Modal.Description style={{paddingTop: '2em'}}>
                        Please select Community interface for any non-participant related events.
                      </Modal.Description>
                      <Modal.Content>
                        <Button color="orange" onClick={() => props.setBaseActiveIndex('base', undefined, 'Community')}>Community</Button>
                        <Button color="violet" onClick={() => props.setBaseActiveIndex('base', undefined, 'Participant')}>Participant</Button>
                      </Modal.Content>
                    </Modal>
                  </React.Fragment>
                : null}
            <p>{leftFooter}</p>
            <React.Fragment>{modal}</React.Fragment>
          </div>

        )}
        setActiveComponent={props.setActiveComponent}
        recordID={props.recordID}
        getRecordInput={props.getRecordInput}
        setBaseActiveIndex={props.setBaseActiveIndex}
        activeComponent={props.activeComponent}
        profile={props.profile}
        updateBaseNotes={props.updateBaseNotes}
        updateSmartID={props.updateSmartID}
        recordType={props.recordType}
        baseObject={props.baseObject}
        getRecords={getRecords}
      />
    }
    </BaseObjectConsumer>
    </Tab.Pane>
  ),
};

const reportsPane = {
  menuItem: {
    key: 'reports',
    icon: 'chart line',
    content: 'Reports',
    style: { color: 'white' },
  },
  render: (props) => (
    props.panes !== undefined && props.profile !== undefined ? (
      props.panes.forEach( (pane) => {

        pane.menuItem.className = `menu${pane.menuItem.content}${props.profile.role.name}`;
      })
     ) : null,

     <Tab.Pane
     attached={true}
     style={{
       border: 'none',
       boxShadow: 'none',
       borderRadius: '0px',
       width: '100%',
      }}
      >
      <Reports
        render={({ content, rightHeader }) => (
          <div>
            {content}
            <p>{rightHeader}</p>
          </div>
        )}
        profile={props.profile}
      />
    </Tab.Pane>
  ),
};

const biReportingPane = {
  menuItem: {
    key: 'biReporting',
    icon: 'grid layout',
    content: 'BI Reporting',
    style: { color: 'white' },
  },
  render: () => (
    <Tab.Pane
      attached={true}
      className=""
      style={{
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0px',
        width: '100%',
      }}
    >
      <BiReporting />
    </Tab.Pane>
  ),
};

export const SidebarPanes = (props) => {
    switch (props.profile.role.id) {
      case 1:
        return [
          dashboardPane,
          eventsPane,
          reportsPane,
          biReportingPane
        ];
      case 2:
      case 3:
        return [
          dashboardPane,
          eventsPane,
          reportsPane
        ];
      case 4:
        return [
          dashboardPane,
          reportsPane
        ];
      default:
        return [];
    }
};



const tabletEventsPane = {
  menuItem: {
    key: 'events',
    icon: 'medkit',
    content: 'Events',
    style: { color: 'white' },
  },
  render: props => (
    <Tab.Pane
      attached={true}
      className="UIMain"
      style={{
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0px',
        width: '100%',
      }}
    >
    <BaseObjectConsumer>
    {({getRecords}) =>
      <Events
        render={({
          baseRecord,
          modal,
          leftFooter,
          baseRecords,
        }) => (
          <div>
            {props.activeComponent === 'base'
              ? baseRecord
              : props.activeComponent === 'baseRecord'
                ? baseRecords
                : props.activeComponent === 'community'
                ? baseRecord
                : props.activeComponent === 'eventTypeCheck' ?
                  <React.Fragment>
                    <Modal
                      open={true}
                      centered
                      style={{textAlign: 'center'}}
                    >
                      <Modal.Header textAlign="middle">
                        Would you like to load the Community or Participant interface?
                      </Modal.Header>
                      <Modal.Description style={{paddingTop: '2em'}}>
                        Please select Community interface for any non-participant related events.
                      </Modal.Description>
                      <Modal.Content>
                        <Button color="orange" onClick={() => props.setBaseActiveIndex('base', undefined, 'Community')}>Community</Button>
                        <Button color="violet" onClick={() => props.setBaseActiveIndex('base', undefined, 'Participant')}>Participant</Button>
                      </Modal.Content>
                    </Modal>
                  </React.Fragment>
                : null}
            <p>{leftFooter}</p>
            <React.Fragment>{modal}</React.Fragment>
          </div>

        )}
        setActiveComponent={props.setActiveComponent}
        recordID={props.recordID}
        getRecordInput={props.getRecordInput}
        setBaseActiveIndex={props.setBaseActiveIndex}
        activeComponent={props.activeComponent}
        profile={props.profile}
        updateBaseNotes={props.updateBaseNotes}
        updateSmartID={props.updateSmartID}
        recordType={props.recordType}
        baseObject={props.baseObject}
        getRecords={getRecords}
      />
    }
    </BaseObjectConsumer>
    </Tab.Pane>
  ),
};

export const tabletSidebarPanes = (props) => {
  if ( props.profile !== undefined ) {
    switch (props.profile.role.id) {
      case 1:
      case 2:
      case 3:
        return [
          tabletEventsPane
        ];
      case 4:
        return [];
      default:
        return [];
    }
  }
  return [];
};
