import React from 'react';
import { Ref, Input, Button, Header, List, Popup } from 'semantic-ui-react';
import btoa from 'btoa';
import PasswordInstructions from './PasswordInstructions';
import PasswordConfirm from './PasswordConfirm';
import PasswordInstructionsError from './PasswordInstructionsError';
import PasswordConfirmError from './PasswordConfirmError';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      penCode: '',
      passwordInstructionsOpen: false,
      passwordConfirmOpen: false,
      passwordInstructionsErrorOpen: false,
      passwordConfirmErrorOpen: false
    };
    this.contextRef = React.createRef();
    this.confirmContextRef = React.createRef();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleSubmit = () => {
    if (this.props.match.path === '/reset' ) {
      let penCode = this.props.location.pathname.split('/');
      console.log( penCode[2] )
      let password = this.state.password;
      let confirmPassword = this.state.confirmPassword;
      if ( /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!#$%^&*()_=+[\]{}\\|;:’“,<.>/?-])(?:.){8,}$/.test( password ) ) {
        if ( password === confirmPassword ) {
          this.props.resetError()
          this.props.changePassword( penCode[2], btoa(password) )
          this.props.history.push('/login')
        } else {
          this.setState({ passwordConfirmErrorOpen: true });
        }
      } else {
        this.setState({ passwordInstructionsErrorOpen: true });
      }
    } else {

      if (this.props.loggedIn === false && this.props.forgotPass == false && this.props.changePass == false) {
        const username = this.state.username;
        const password = this.state.password;
        this.props.auth(username, password);
      }

      if (this.props.loggedIn === false && this.props.forgotPass == true) {
        if (this.state.username.length > 1) {
          this.props.resetPass(this.state.username);
          this.setState({ username: '', password: '' });
        }
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.match.path !== '/reset' ?  (
          <Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Username"
            name="username"
            value={this.state.username}
            onChange={this.handleChange}
            style={{ margin: '0.5em' }}
          />
        ) : null}
        {this.props.match.path === '/reset'  ? (
          <React.Fragment>
            <Ref innerRef={ref => this.contextRef = ref }>
              <Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="New Password"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                style={{ margin: '0.5em' }}
                onFocus={ () => this.setState({ passwordInstructionsOpen: true }) }
                onBlur={ () => this.setState({ passwordInstructionsOpen: false }) }
              />
            </Ref>
            <Ref innerRef={ref => this.confirmContextRef = ref }>
              <Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Confirm New Password"
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                style={{ margin: '0.5em' }}
                onFocus={ () => this.setState({ passwordConfirmOpen: true }) }
                onBlur={ () => this.setState({ passwordConfirmOpen: false }) }
              />
            </Ref>
          </React.Fragment>
        ) : null}
        {this.props.match.path !== '/reset' && this.props.forgotPass == false ? (
          <React.Fragment>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              style={{ margin: '0.5em' }}
            />
          </React.Fragment>
        ) : null}
        {this.props.match.path !== '/reset' ? (
        <Button
          className="btn purple"
          fluid
          size="large"
          style={{
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '97%',
          }}
          disabled={this.state.username.length < 5 ?  true : this.state.password.length < 5 && this.props.forgotPass == false ? true : false}
          onClick={this.handleSubmit}
        >
        {this.props.forgotPass !== true && this.props.match.path !== '/reset'
        ? 'Authorize Access'
        : 'Reset Password'}
    </Button>
        ) : (
          <Button
          className="btn purple"
          fluid
          size="large"
          style={{
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '97%',
          }}
          disabled={ this.state.password.length < 8 || this.state.confirmPassword !== this.state.password ? true : false}
          onClick={this.handleSubmit}
        >
        Reset Password
    </Button>
        )}

        {this.props.forgotPass == false &&
        this.props.passwordReset == false &&
        this.props.match.path !== '/reset' ? (
          <React.Fragment>
            <Button
              className="btnForgotPassword"
              basic
              onClick={() => this.props.setChangeForgotPass('forgot')}
            >
              Forgot Password
            </Button>

          </React.Fragment>
        ) : (
          this.props.forgotPass == true ? (
          <Button
            className="btnForgotPassword"
            basic
            onClick={() => this.props.setChangeForgotPass('home')}
          >
            Cancel
          </Button>
          ) :  null
        )}
        {this.props.match.path === '/reset'  ? (
          <Button
            className="btnForgotPassword"
            basic
            onClick={() => this.props.history.push('/login')}
          >
            Cancel
          </Button>
        )  :  null}
        <Header sub>
          Due to HIPAA regulations, never save your password to your browser's
          password manager
        </Header>
        <PasswordInstructions
          context={ this.contextRef }
          open={ this.state.passwordInstructionsOpen }
          password={ this.state.password }
        />
        <PasswordConfirm
          context={ this.confirmContextRef }
          open={ this.state.passwordConfirmOpen }
          password={ this.state.password }
          confirm={ this.state.confirmPassword }
        />
        <PasswordInstructionsError
          open={ this.state.passwordInstructionsErrorOpen }
          close={ () => this.setState({ passwordInstructionsErrorOpen: false })}
          password={ this.state.password }
        />
        <PasswordConfirmError
          open={ this.state.passwordConfirmErrorOpen }
          close={ () => this.setState({ passwordConfirmErrorOpen: false })}
        />
      </React.Fragment>
    );
  }
}
