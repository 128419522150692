import React from 'react';
import { Modal, Button, Icon, List, Header, Segment } from 'semantic-ui-react';
import Moment from 'react-moment';

export class EditNotificationsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false, buttonColor: '' };
  }

  handleModal = () => {
    this.setState( prevState => ({ open: !prevState.open }) );
  }

  renderNotificationStatusButton = () => {
    if (this.props.notification !== undefined) {
      if (this.props.notification.notificationStatus === 12) {
        return 'Dismiss Notification';
      } else if (this.props.notification.notificationStatus === 13) {
        return 'Undismiss Notification';
      }
    }
  }

  render() {
    const { notification } = this.props;
    return (
      <Modal
        trigger={
          <Button
            icon
            labelPosition="left"
            className="btnOpenNotification"
            onClick={() => {
              this.handleModal();
            }}
          >
            <Icon name="envelope open" />
          </Button>
        }
        open={this.state.open}
        onClose={this.handleModal}
        size="small"
        closeIcon
      >
        <Modal.Content>
          <Header dividing>Notification</Header>
          <Segment raised clearing>
            <p>{notification.content}</p>
            <Button
              floated="right"
              color={notification.notificationStatus === 12 ? 'red' : 'green'}
              onClick={() => this.props.changeNotificationStatus(notification)}
            >
              {this.renderNotificationStatusButton()}
            </Button>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}
