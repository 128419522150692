import React from 'react';
import { Modal, Button, Icon, List, Header, Segment } from 'semantic-ui-react';
import { url, key, getCookie } from '../../Util';
import moment from 'moment';
import Moment from 'react-moment';

export class EditNotesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false, buttonColor: '' };
  }


  handleModal = () => {
    this.setState({ open: !this.state.open });
  }

  renderNoteStatusButton = () => {
    if (this.props.note !== undefined) {
      if (this.props.note.notificationStatus === 12) {
        return 'Dismiss Note';
      } else if (this.props.note.notificationStatus === 13) {
        return 'Undismiss Notification';
      }
    }
  }

  render() {
    const { note } = this.props;
    return (
      <Modal
        trigger={
          <Button
            icon
            compact
            labelPosition="left"
            style={{ marginTop: '-1em' }}
            floated="right"
            color="violet"
            onClick={this.handleModal}
          >
            <Icon name="envelope open" />
            Open
          </Button>
        }
        open={this.state.open}
        onClose={this.handleModal}
        size="large"
        closeIcon
      >
        <Modal.Content>
          <Header dividing>Note</Header>
          <Segment raised>
            <Header>
              ID: {note.id}
              <Header sub>
                Date Recorded:{' '}
                <Moment format="MM/DD/YYYY">{note.dateRecorded}</Moment>
              </Header>
            </Header>
            <Header subheader>{note.content}</Header>
            <Button
              color={note.notificationStatus === 12 ? 'red' : 'green'}
              onClick={() => this.props.addUpdateBaseNotes('changeStatus', note)}
            >
              {this.renderNoteStatusButton()}
            </Button>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}
