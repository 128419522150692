import React, { useRef, useState } from 'react';
import { Form, Header, Dropdown, Button, Icon, Input, Ref } from 'semantic-ui-react';
import PhoneInput from "react-phone-input-auto-format";
import PasswordInstructions from '../Login/PasswordInstructions';

export class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { buttonColor: 'yellow', confirmDelete: false, confirmReset: false };
  }

  changeConfirmReset = () => {
    this.setState({confirmReset: !this.state.confirmReset});
  }

  changeConfirmDelete = () => {
    this.setState({confirmDelete: !this.state.confirmDelete});
  }


  render() {
    const newUser = (
      <NewUser
        siteOptions={this.props.siteOptions}
        handleNewUser={this.props.handleNewUser}
        handleNewUserPhone={this.props.handleNewUserPhone}
        user={this.props.user}
        userRoleOptions={this.props.userRoleOptions}
      />
    );
    const editUser = (
      <EditUser
        siteOptions={this.props.siteOptions}
        user={this.props.user}
        buttonColor={this.state.buttonColor}
        handleUserChange={this.props.handleUserChange}
        handleUserPhoneChange={this.props.handleUserPhoneChange}
        deleteUser={this.props.deleteUser}
        userRoleOptions={this.props.userRoleOptions}
        resetUserPassword={this.props.resetUserPassword}
        resetSuccess={this.props.resetSuccess}
        confirmReset={this.state.confirmReset}
        changeConfirmReset={this.changeConfirmReset}
        confirmDelete={this.state.confirmDelete}
        changeConfirmDelete={this.changeConfirmDelete}
      />
    );
    return (
      <React.Fragment>
        {this.props.render({
          newUser: newUser,
          editUser: editUser,
        })}
      </React.Fragment>
    );
  }
}

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordInstructionsOpen: false
    };
    this.contextRef = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <Form.Group widths='equal'>
        <Form.Input
          name="firstName"
          label="First Name"
          value={this.props.user.firstName}
          onChange={this.props.handleNewUser}
        />

        <Form.Input
          name="lastName"
          label="Last Name"
          value={this.props.user.lastName}
          onChange={this.props.handleNewUser}
        />
        </Form.Group>
        <Form.Group>
        <Form.Input
          name="userName"
          label="User Name"
          width={16}
          value={this.props.user.userName}
          onChange={this.props.handleNewUser}
        />
         </Form.Group>
        <Form.Group>
        <Form.Input
          name="email"
          label="Email"
          width={16}
          value={this.props.user.email}
          onChange={this.props.handleNewUser}
        />
         </Form.Group>

        <PhoneInput
          name="phone"
          inputComponent={Form.Input}
          label="Phone"
          width={7}
          value={this.props.user.phone}
          onChange={this.props.handleNewUserPhone}
        />

        <Form.Dropdown
          name="role"
          label="User Role"
          width={7}
          selection
          placeholder="Select User Role"
          options={this.props.userRoleOptions}
          value={this.props.user.role}
          onChange={this.props.handleNewUser}
        />
        <Form.Group>
          <Ref innerRef={ ref => this.contextRef = ref }>
            <Form.Input
              name="password"
              type="password"
              label="Password"
              width={8}
              value={this.props.user.password}
              onChange={this.props.handleNewUser}
              onFocus={ () => {
                this.setState({ passwordInstructionsOpen: true });
              }}
              onBlur={ () => this.setState({ passwordInstructionsOpen: false }) }
            />
          </Ref>
        </Form.Group>
        <Form.Group>
        <Form.Dropdown
          placeholder="Select Site"
          label="Site"
          selection
           width={16}
          options={this.props.siteOptions}
          name="site"
          onChange={this.props.handleNewUser}
        />
         </Form.Group>
         <PasswordInstructions
           context={ this.contextRef }
           open={ this.state.passwordInstructionsOpen }
           password={ this.props.user.password || '' }
         />
      </React.Fragment>
    );
  }
};

const EditUser = props => {
  return (
    <React.Fragment>
       <Form.Group widths='equal'>
      <Form.Input
        name="firstName"
        label="First Name"
        value={props.user.firstName}
        onChange={props.handleUserChange}
      />

      <Form.Input
        name="lastName"
        label="Last Name"
        value={props.user.lastName}
        onChange={props.handleUserChange}
      />
       </Form.Group>
         <Form.Group>
      <Form.Input
        name="userName"
        label="User Name"
         width={16}
        value={props.user.userName}
        onChange={props.handleUserChange}
      />
       </Form.Group>
         <Form.Group>
      <Form.Input
        name="email"
        label="Email"
         width={16}
        value={props.user.email}
        onChange={props.handleUserChange}
      />
       </Form.Group>
          <Form.Group>
      <PhoneInput
        name="phone"
        label="Phone"
        width={7}
        inputComponent={Form.Input}
        value={props.user.phone}
        onChange={props.handleUserPhoneChange}
      />
       <Form.Dropdown
        name="role"
        label="User Role"
        width={7}
        selection
        placeholder="Select User Role"
        options={props.userRoleOptions}
        value={props.user.role}
        onChange={props.handleUserChange}
      />
           </Form.Group>
          <Form.Group>
      <Form.Dropdown
        placeholder="Select Site"
        selection
        name="site"
        label="Site"
         width={16}
         options={props.siteOptions}
        value={props.user.site}
        onChange={props.handleUserChange}
      />
      </Form.Group>
      <Header dividing>Reset Password</Header>
      <Button
        animated="vertical"
        color={props.resetSuccess == true ? 'green' : props.resetSuccess == false ? 'red' : props.buttonColor }
        inverted
        disabled={ props.resetSuccess == true }
        style={{ width: '50%', left: '25%', right: '25%' }}
        onClick={ props.resetSuccess == true ? null : props.confirmReset == false ? props.changeConfirmReset : props.resetUserPassword }
      >
        <Button.Content hidden>{ props.confirmReset == false ? 'Reset Password' : 'Are You Sure?' }</Button.Content>
        <Button.Content visible>
          { props.resetSuccess == true ?
            'Email Link Sent' :
            <Icon name="sync" />
          }
         </Button.Content>
      </Button>
      <Header dividing>Delete User</Header>
      <Button
        animated="vertical"
        color="red"
        inverted
        style={{ width: '50%', left: '25%', right: '25%' }}
        onClick={props.confirmDelete == false ? props.changeConfirmDelete : props.confirmDelete == true ? props.deleteUser : null}
      >
        <Button.Content hidden>{props.confirmDelete == false ? 'Delete User' : props.confirmDelete == true ? 'Are You Sure?' : null}</Button.Content>
        <Button.Content visible>
          <Icon name="close" />
         </Button.Content>
      </Button>
    </React.Fragment>
  );
};
