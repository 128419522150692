import React from 'react';
import {
  Segment,
  Button,
  Icon,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import _ from 'lodash';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';
import { NotCollection } from '../Interface/NotCollection';
import { IsCollection } from '../Interface/IsCollection';
import { getCookie } from '../../Util';
import { AIContextProvider } from '../../Context/AIContext';

export class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: 0,
      isCollection: false,
      isDisabled: false,
      modalOpen: false,
      baseObject: {},
      isReadOnly: false,
      showPopup: false,
      message: '',
    };
  }

  async componentDidMount () {

    if (this.state.token === undefined) {
      let token = getCookie('token');
     await  this.setState({ token });
    }

    if (this.props.collection !== undefined) {
      this.setState({ collection: this.props.collection });
    }

    if (
      this.props.elements !== undefined &&
      this.props.baseObject !== undefined
    ) {
      this.setState({ elements: this.props.elements });
    }

    if (this.props.profile !== undefined) {
      let profile = this.props.profile;
      if (profile.role.id == 4) {
        this.setState({ isReadOnly: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.collection !== this.state.collection) {
      this.setState({
        collection: this.props.collection,
        elements: this.state.elements,
      });
    }

    if (this.props.collection !== prevProps.collection) {
      let inputData = this.props.inputData;
      let baseObject = this.props.baseObject;
      this.setState({
        baseObject,
        inputData,
        collection: this.props.collection,
      });
    }
    if (
      prevProps.baseObject !== this.props.baseObject ||
      prevProps.inputData !== this.props.inputData
    ) {
      let inputData = this.props.inputData;
      let baseObject = this.props.baseObject;
      this.setState({ baseObject, inputData });
    }
    if (this.props.activeIndex !== prevProps.activeIndex) {
      let inputData = _.concat(this.props.baseObject.inputData, this.props.baseObject.records[0].inputData);
      let baseObject = this.props.baseObject;
      this.setState({ baseObject, inputData });
    }

    if (
      prevProps.baseObject.records[0].id !== this.props.baseObject.records[0].id
    ) {
      let inputData = _.concat(this.props.baseObject.inputData, this.props.baseObject.records[0].inputData);
      let baseObject = this.props.baseObject;

      this.setState({ baseObject, inputData, elements: this.props.elements });
    }

    if (
      prevProps.elements !== this.props.elements &&
      prevProps.collection === this.props.collection
    ) {
      this.forceRerender();
    }

    if (prevProps.elements !== this.props.elements) {
      let inputData = _.concat(this.props.baseObject.inputData, this.props.baseObject.records[0].inputData);

      let checkedElements = new Set();
      if (
        (inputData  !== undefined && inputData.length > 0)
      ) {;
        for (let i = 0; i < inputData.length; i++) {
          this.props.elements.map(el => {
            if (el.id == inputData[i].elementID) {
              checkedElements.add(el);
            } else {
              checkedElements.add(el);
            }
          });
        }
        const iterator = checkedElements.values();
        let elements = [];
        for (let entry of iterator) {
          elements.push(entry);
        }
        this.setState({ elements });
      } else {
        let elements = this.props.elements;
        this.setState({ elements });
      }
    }
  }

  async forceRerender() {
    await this.setState({
      collection: !this.state.collection,
      elements: this.props.elements,
    });

    this.setState({ collection: !this.state.collection });
  }

  renderDynamicButtonText() {
    const { paneLength, activeIndex } = this.props;
    if (paneLength && activeIndex !== undefined) {
      if (activeIndex < paneLength - 1) {
        return 'Next ';
      } else {
        return 'Save ';
      }
    }
  }

  saveDynamicForm(baseObject) {
    const buttonAction = this.renderDynamicButtonText();
    if (buttonAction !== 'Save ') {
      this.props.setActiveIndex('next');
    } else {
      this.props.postEventRecord();
      this.props.setBaseActiveIndex('base', 1);
      this.setState({ elements: [], inputData: [] });
    }
  }
  showPopup = (message, valid) => {
    console.log(message, valid);
    if (valid !== undefined) {
      this.setState({ showPopup: valid, message: message });
    }
  }

  render() {
    return this.state.elements === undefined ||
      this.state.collection === undefined ? (
      <Segment style={{ height: '300px' }}>
        <Dimmer active size="massive">
          <Loader style={{ marginTop: '10px' }} />
        </Dimmer>
      </Segment>
    ) : this.props.baseObject === undefined ? (
      <Segment style={{ height: '300px' }}>
        <Dimmer active size="massive">
          <Loader style={{ marginTop: '10px' }} />
        </Dimmer>
      </Segment>
    ) : this.props.baseObject !== undefined ? (
      <BaseObjectConsumer>
        {({
          baseObject,
          inputData,
          records,
          record,
          recordInput,
          onChange,
          invalidFields,
          handleCollectionSubmission
        }) => (
          <React.Fragment>
            <AIContextProvider
              values={{
                aiTasks: this.props.aiTasks,
                activeIndex: this.props.activeIndex,
                baseObject,
                elements: this.state.elements,
              }}
            >
              {this.state.collection == false ? (
                <Segment
                  secondary
                  raised
                  style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  className="dynamicUI"
                >
                  <React.Fragment>
                    {this.state.elements.map(el => (
                      <NotCollection
                        invalidFields={invalidFields}
                        displayInvalidMessage={this.displayInvalidMessage}
                        element={el}
                        inputData={inputData}
                        baseObject={baseObject}
                        onChange={onChange}
                        isReadOnly={this.state.isReadOnly}
                        record={record}
                        showPopup={this.showPopup}
                        getPoints={this.props.getPoints}
                      />
                    ))}
                  </React.Fragment>
                </Segment>
              ) : this.state.collection === true ? (
                <IsCollection
                  elements={this.props.elements}
                  baseObject={baseObject}
                  inputData={inputData}
                  collection={this.state.collection}
                  activeComponent={this.props.activeComponent}
                  activeIndex={this.props.activeIndex}
                  isReadOnly={this.state.isReadOnly}
                />

              ) : null}
              <div style={{ marginTop: '2em' }}>
                <Button.Group fluid style={{ padding: '1em' }}>
                  <Button
                    primary
                    disabled={this.props.activeIndex == 0 ? true : false}
                    onClick={() => this.props.setActiveIndex('back')}
                  >
                    <Button.Content visible>
                      <Icon name="arrow left" /> Previous
                    </Button.Content>
                  </Button>

                  <Button
                    color="violet"
                    style={{ marginLeft: '50%' }}
                    onClick={() => this.saveDynamicForm(baseObject)}
                  >
                    <Button.Content visible>
                      <span>{this.renderDynamicButtonText()}</span>
                      <Icon name="arrow right" />
                    </Button.Content>
                  </Button>
                </Button.Group>
              </div>
            </AIContextProvider>
          </React.Fragment>
        )}
      </BaseObjectConsumer>
    ) : null;
  }
}
