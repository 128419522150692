import React from 'react';
import { Segment } from 'semantic-ui-react';
import ReactTable from 'react-table';

function filterCaseInsensitive(filter, row) {
  if(row !== undefined) {
    const id = filter.pivotId || filter.id;
    let returnedFilter;

    return (
      row[id] !== undefined && row[id] !== null ?
        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      :
        true
    );
  }
}

function catchFilterError(filter, row) {
  try {
    return filterCaseInsensitive(filter, row);
  } catch (error) {
    console.log(error);
  }
}

const PlaceholderTable = props => (

  <Segment>
    { console.log( 'props: ', props ) }
    <div style={{ float: 'right', marginBottom: '1em' }}>{props.button}{props.search}{props.modal}</div>
    <ReactTable
      defaultFilterMethod={catchFilterError}
      className={'-striped -highlight ' + props.className}
      data={props.data}
      style={props.style}
      columns={props.columns}
      showPagination={props.showPagination}
      defaultPageSize={10}
      pageSizeOptions={[ 10, 25, 50, 100,  Number(props.recordCount) ]}
      sortable
      filterable
    />
    <div style={{padding: '2em 0 1em 0.5em'}}>
      <p style={{fontWeight: '200'}}>{`Record Count: ${props.recordCount} ${ props.scramblerID ? `for Scrambler ID ${ props.scramblerID }` : props.smartID ? `for Smart ID ${ props.smartID }` : props.dateRange !== undefined ? `for date range ${ props.dateRange }` : '' }` }</p>
    </div>
  </Segment>
);

export default PlaceholderTable;
