import React from 'react';
import { Dimmer, Button, Loader, Header, Segment } from 'semantic-ui-react';
import { NavigationBar } from '../Interface/Interface';
import { TabHeader } from '../TabHeader/TabHeader';
import { AdminModals } from '../Modals/AdminModals';
import PlaceholderTable from '../Tables/PlaceholderTable';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';

export default class NotificationAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userNotifications: [],
      notifications: [],
      isLoading: false,
      confirm: false,
      delete: '',
    };
  }

  async componentDidMount() {
    let token = getCookie('token');
    this.setState({ token, isLoading: true });
    await this.getNotifications();
  }

  componentDidUpdate( prevProps, prevState ) {
    if (prevState.notifications !== this.state.notifications ) {
      this.getUserNotifications()
    }
  }

  toggleDeleteError = notification => {
    this.setState({ confirm: true, delete: notification });
  }

  deleteNotification = notification => {
    this.props.updateCounter(1,0);
    axios({
      method: 'delete',
      url: url + 'notification/' + notification,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.props.updateCounter(0,1);
      this.getNotifications();
      this.setState({ confirm: false, isLoading: false });
    });
  }

  getUserNotifications = () => {
    axios({
      method: 'get',
      url: url + 'Notifications/User',
      headers: {
        api_key: key,
        AccessToken: getCookie('token')
      },
    }).then(res => {
      // this.setState({userNotifications: res.data, isLoading: false})
      this.props.listNotifications( res.data );
    });
  }

  getNotifications = () => {
    axios({
      method: 'get',
      url: url + 'Notifications',
      headers: {
        api_key: key,
        AccessToken: getCookie('token')
      },
    }).then(res => {
      this.setState({notifications: res.data, isLoading: false})
    });
  }

  render() {
    const columns = [
      { Header: 'Notification', accessor: 'content' },
      {
        Header: 'Modify Notification',
        accessor: 'id',
        filterable: false,
        Cell: row => (

          <AdminModals
            buttonText="Select Notification"
            color="grey"
            notificationRow={row}
            notificationID={row.row.id}
            notifications={this.state.notifications}
            getNotifications={this.getNotifications}
            updateCounter={this.props.updateCounter}
          />
        ),
      },
      {
        Header: 'Delete Notification',
        accessor: 'id',
        filterable: false,
        Cell: row => (
          <Button
            negative
            content="Delete"
            icon="close"
            onClick={() => this.toggleDeleteError(row.original.id)}
          />
        ),
      },
    ];

    const data = this.state.notifications;
    const Modal = (
      <AdminModals
        buttonText="New Notification"
        color="violet"
        notifications={this.state.notifications}
        getNotifications={this.getNotifications}
        updateCounter={this.props.updateCounter}
      />
    );
    const content = (
      <PlaceholderTable
        className="tblNotifications"
        style={{ textAlign: 'left', clear: 'right', padding: '5px' }}
        modal={Modal}
        columns={columns}
        data={data}
        recordCount={data.length}
        token={getCookie('token')}
      />
    );

    return (
      <div
        style={{
          background: '#f5f5f5',
          paddingTop: '1em',
          paddingLeft: '10px',
        }}
      >
        <Dimmer active={this.state.isLoading === true}>
          <Loader active={this.state.isLoading === true} />
        </Dimmer>
        <NavigationBar setBaseActiveIndex={this.props.setBaseActiveIndex}profile={this.props.profile}  />
        <TabHeader titleLeft="NOTIFICATION ADMINISTRATION" />
        {this.state.confirm === true ? (
          <Segment
            textAlign="center"
            style={{ marginLeft: 'auto', marginRight: 'auto', width: '75%' }}
          >
            <Header>Are You Sure You Want To Delete This Notification?</Header>
            <Button
              positive
              content="Confirm"
              onClick={() => this.deleteNotification(this.state.delete)}
            />
            <Button
              negative
              content="Cancel"
              onClick={() => this.setState({ confirm: false })}
            />
          </Segment>
        ) : null}
        {this.props.render({ content })}
      </div>
    );
  }
}
