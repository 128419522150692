import React from 'react';
import { Modal } from 'semantic-ui-react';

const DuplicateSiteNameModal = ({ open, close }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="small"
    >
      <Modal.Header>
        Duplicate login Error!
      </Modal.Header>
      <Modal.Content>
        <p>An error occurred and we were unable to create the site! It appears that a site with that site name already exists. Please try again with a unique site name.</p>
      </Modal.Content>
    </Modal>
  );
};

export default DuplicateSiteNameModal;