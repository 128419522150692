import React from 'react';
import { TabHeader } from '../TabHeader/TabHeader';
import PlaceholderTable from '../Tables/PlaceholderTable';
import { AdminModals } from '../Modals/AdminModals';
import axios from 'axios';
import { NavigationBar } from '../Interface/Interface';
import { url, key, getCookie } from '../../Util';
import { Dimmer, Loader } from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-auto-format';
import { APIContextConsumer } from '../../Context/APIProgressContext';

export class SiteAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sites: [], isLoading: false };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getSites();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }
    if (this.state.sites.length !== prevState.sites.length) {
      this.setState({ isLoading: true });
      this.getSites();
    }

    if (this.state.sites !== prevState.sites && this.state.sites.length !== prevState.sites.length) {
      this.getSites();
    }
  }

  getSites = () => {
    this.props.updateCounter(1,0);
    axios({
      method: 'get',
      url: url + 'sites',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.setState({ sites: res.data, isLoading: false });
      this.props.updateCounter(0,1);
    });
  }

  render() {
    const columns = [
      { Header: 'Site', accessor: 'name' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Contact Name', accessor: 'contactName' },
      { Header: 'Contact Email', accessor: 'contactEmail' },
      {
        Header: 'Contact Phone',
        accessor: 'contactPhone',
        Cell: row => (
          <PhoneInput
            value={row.value || ''}
            style={{
              textAlign: 'center',
              border: 'none',
              background: 'transparent',
              verticalAlign: 'middle',
            }}
          />
        ),
      },
      {
        Header: 'Modify Site',
        accessor: 'id',
        filterable: false,
        Cell: row => (
          <APIContextConsumer>
            {({ updateCounter }) => (
              <AdminModals
                buttonText="Select Site"
                color="grey"
                siteID={row.row.id}
                siteRow={row}
                getSites={this.getSites}
                sites={this.state.sites}
                updateCounter={updateCounter}
              />
            )}
          </APIContextConsumer>
        ),
      },
    ];
    const data = this.state.sites;
    const Modal = (
      <APIContextConsumer>
      {({ updateCounter }) => (
      <AdminModals
        buttonText="New Site"
        color="violet"
        getSites={this.getSites}
        sites={this.state.sites}
        updateCounter={updateCounter}
      />
      )}
      </APIContextConsumer>
    );
    const content = (
      <PlaceholderTable
        className="tblSiteAdmins"
        style={{ textAlign: 'left', clear: 'right', padding: '5px' }}
        modal={Modal}
        columns={columns}
        data={data}
        recordCount={data.length}
      />
    );

    return (
      <div
        style={{
          background: '#f5f5f5',
          paddingTop: '1em',
          paddingLeft: '10px',
        }}
      >
        <Dimmer active={this.state.isLoading === true}>
          <Loader active={this.state.isLoading === true} />
        </Dimmer>
        <NavigationBar
          setBaseActiveIndex={this.props.setBaseActiveIndex}
          profile={this.props.profile}
        />
        <TabHeader titleLeft="SITE ADMINISTRATION" />
        {this.props.render({ content: content })}
      </div>
    );
  }
}
