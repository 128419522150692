import React from 'react';
import { Icon, List, Modal } from 'semantic-ui-react';
import { validateEmail, validatePhone } from '../../Util';

const SiteError = ({ open, close, site }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="small"
    >
      <Modal.Header>
        One or more required fields are missing
      </Modal.Header>
      <Modal.Content>
        <List bulleted>
          <List.Header>
            Please confirm that you have entered the following fields:
          </List.Header>
          <br />
          <List.Content>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ site.name ? 'green' : 'red' }
                name={ site.name ? 'check' : 'times' }
              />
              &nbsp;
              Site Name
            </List.Item>
          </List.Content>
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default SiteError;