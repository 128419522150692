import React from 'react';
import axios from 'axios';
import { key, url, getCookie } from '../Util';

const APIContext = React.createContext();

export class APIContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {apiProgressCounter: 0, inProgress: 0, finished: 0};

  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }

    if (this.props.values !== undefined && this.props.values !== undefined) {
      this.setState({ apiProgressCounter: 0, inProgress: this.props.values.inProgress, finished: this.props.values.finished});
    }
  }


  updateCounter = (inProgress, finished) => {
   if(inProgress > 0 && inProgress !== undefined) {
    this.setState({inProgress: this.state.inProgress + inProgress,  apiProgressCounter: this.state.inProgress - this.state.finished});
   }

   if(finished > 0) {
    this.setState({finished: this.state.finished + finished, apiProgressCounter: this.state.inProgress - this.state.finished});
   }
  }



  componentDidUpdate(prevProps, prevState) {
    const {inProgress, finished} = this.props.values;
    if(prevProps.values.inProgress !== inProgress) {

      this.setState({inProgress});
    }

    if(prevProps.values.finished !== finished) {
      let {finished} = this.props.values;
      this.setState({finished});
    }

    if(prevState.finished !== this.state.finished || prevState.inProgress !== this.state.inProgress) {
      this.setState({apiProgressCounter: this.state.inProgress - this.state.finished});
    }

  }



  render() {

    const { children } = this.props;
    return (
      <APIContext.Provider
        value={{
          apiProgressCounter: this.state.apiProgressCounter,
          updateCounter: this.updateCounter
        }}
      >
        {children}
      </APIContext.Provider>
    );
  }
}

export const APIContextConsumer = APIContext.Consumer;
