import React from 'react';
import { Popup, Dropdown, Icon, Button, Card, Label } from 'semantic-ui-react';
import { UserAdmin } from '../Admin/UserAdmin';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';
import { NotificationsList } from '../Sidebar/NotificationsList';
import NotificationsListModal from '../Modals/NotificationsListModal';
const value = 'ISDH | SSP Application';

export const Title = props => {
  return (
    <Button
    className="btnHome"
      onClick={() => props.setActiveComponent('base')}
      style={{ background: 'none' }}
    >
    <img
        className="loginLogo"
        src="assets/images/logo_small.png"
        alt="Indiana State Seal"
      />
      <h3>{props.title}</h3>
    </Button>
  );
};

export class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { profile: {} };
  }

  render() {
    const { profile } = this.props;
    return (
      <Popup
        trigger={
          <Icon name="user circle" color="black" size="large" circular />
        }
        content={
          <Card raised>
            <Card.Content>{this.props.render({ profile })}</Card.Content>
          </Card>
        }
        on={'click'}
        position="bottom center"
        hideOnScroll
      />
    );
  }
}

export class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showNotificationsListModal: false
    };
  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
      axios({
        method: 'get',
        url: url + 'Notifications/User',
        headers: {
          api_key: key,
          AccessToken: token,
        },
      }).then(res => this.props.listNotifications(res.data));
    }
  }

  componentDidUpdate( prevProps, prevState ) {
    if ( prevProps.notificationsList !== this.props.notificationsList ) {
      this.props.checkNotifications();
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  toggleNotificationsListModal = () => {
    this.setState( prevState => ({ showNotificationsListModal: !prevState.showNotificationsListModal }) );
  }

  render() {
    return (
      <React.Fragment>
        <Popup
          trigger={
            <Button
              basic
              circular
              compact
              inverted
              style={{ padding: 0 }}
            >
              <Icon name="bell" color={ this.props.showBadge === true ? 'red' : 'black' }  size="large" circular />
            </Button>
          }
          wide="very"
          open={ this.state.isOpen }
          onClose={ this.handleClose }
          onOpen={ this.handleOpen }
          content={
              <NotificationsList
                notificationsList={ this.props.notificationsList ? this.props.notificationsList.slice(0,5) : [] }
                notificationCount={ this.props.notificationsList ? this.props.notificationsList.length : 0 }
                listNotifications={this.props.listNotifications}
                handleButtonClick={this.props.handleButtonClick}
                closePopup={ this.handleClose }
                viewMore={
                  this.props.notificationsList 
                    ? this.props.notificationsList.length > 5
                      ? () => {
                          this.handleClose();
                          this.toggleNotificationsListModal();
                        }
                      : null
                    : null
                }
              />
          }
          on={ 'click' }
          position="bottom center"
        />
        <NotificationsListModal
          open={this.state.showNotificationsListModal}
          handleModal={ this.toggleNotificationsListModal }
          notificationsList={this.props.notificationsList}
          notificationCount={ this.props.notificationsList ? this.props.notificationsList.length : 0 }
          listNotifications={this.props.listNotifications}
          handleButtonClick={this.props.handleButtonClick}
        />
      </React.Fragment>
    );
  }
}

export class Settings extends React.Component {
  render() {
    return (
      <Dropdown
        icon={<Icon circular color="grey" size="large" name="settings" />}
        // simple
        closeOnChange='true'
      >
      <Dropdown.Menu style={{ left: '-60px' }}>

          <Dropdown.Item
            onClick={() => this.props.setActiveComponent('userAdmin')}
          >
            User Admin
          </Dropdown.Item>
          {this.props.profile !== undefined && this.props.profile.role.id == 1 ?
            <React.Fragment>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.props.setActiveComponent('siteAdmin')}
          >
            Site Admin
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.props.setActiveComponent('notificationAdmin')}
          >
            Notification Admin
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.props.setActiveComponent('providerAdmin')}>
            Provider Admin
          </Dropdown.Item>
          </React.Fragment>
          : null
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export class Header extends React.Component {
  render() {
    return this.props.render({ title: value });
  }
}
