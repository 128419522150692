import React from 'react';
import {Segment, Divider} from 'semantic-ui-react';

export const TabHeader = ({ titleLeft, titleRight }) => {
  return (
    <React.Fragment>
      <Segment.Group
        horizontal
        style={{ boxShadow: 'none', border: 'none', marginTop: '0px', backgroundColor: 'transparent' }}
      >
        <Segment
          compact
          inline='true'
          textAlign="left"
          basic
          style={{ border: 'none', padding: '0px', marginBottom: '-10px' }}
        >
          <React.Fragment>
            {titleLeft !== undefined ? <h3>{titleLeft}</h3> : null}
          </React.Fragment>
        </Segment>
        <Segment
          compact
          inline='true'
          textAlign="right"
          basic
          style={{ border: 'none', padding: '0px', marginBottom: '-10px' }}
        >
          <React.Fragment>
            {titleRight !== undefined ? <p>{titleRight}</p> : null}
          </React.Fragment>
        </Segment>
      </Segment.Group>
      <Divider />
    </React.Fragment>
  );
};
