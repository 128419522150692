import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

const QaWarningModal = () => {

  const [ open, setOpen ] = useState( window.location.host.includes( 'qa' ) )

  return (
    <Modal
      open={ open }
      onClose={ () => setOpen( false )}
      size="mini"
    >
      <Modal.Header>
        Quality Assurance
      </Modal.Header>
      <Modal.Content>
        <p>You are currently accessing a testing and quality assurance version of the ISDH SSP Application. All data on this QA site should be considered test data and may become unavailable, outdated, and incorrect.</p>
        <p>If you are trying to update the live system please <a href="https://ssd.isdh.in.gov/login">visit https://ssd.isdh.in.gov/login.</a></p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={ () => setOpen( false )}
        >
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default QaWarningModal;
