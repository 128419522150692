import React from 'react';
import { Button, Segment, Dimmer, Loader, Icon, Modal } from 'semantic-ui-react';
import PlaceholderTable from '../Tables/PlaceholderTable';
import { EventsModals } from '../Modals/EventsModals';
import axios from 'axios';
import { key, url, getCookie } from '../../Util';
import { ParticipantHeader } from '../ParticipantHeader/ParticipantHeader';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import {
  BaseObjectProvider,
  BaseObjectContext,
  BaseObjectConsumer,
} from '../../Context/BaseObjectContext';
import moment from 'moment';
import _ from 'lodash';

export class BaseRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmDelete: false,
      idToDelete: ''
    };
  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token, isLoading: true});
    }

    if (this.props.baseObject !== undefined) {
      this.getRecords();
    }
    this.notify();
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.context.isLoading !== this.state.isLoading) {
      this.setState({isLoading: this.context.isLoading});
    }
    // if ( !_.isEqual(prevProps.baseObject.notes, this.props.baseObject.notes) ) {
    //   this.notify()
    // }
  }

  notify = () => {
    let toastId = this.props.recordID || null;
    // let customToastId = 'xxx-yyy';
    if (this.props.baseObject !== undefined) {
      let notes = this.props.baseObject.notes;

      let notDismissedNotes = notes.filter(
        note => note.notificationStatus !== 13
      );


      if (notDismissedNotes.length > 0) {
        // let filter = notDismissedNotes.filter(
        //   note => Date.parse(note.dateRecorded) > Date.parse(lastLogin)
        // );

        // if (filter !== undefined && filter.length > 0 && !toast.isActive(toastId)) {

        toast.info('You have participant notes', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

      }
    }
  }

  getRecords() {
    this.setState({isLoading: true});
    this.props.getRecords();
  }

  updateBaseNotes = notes => {
    const baseObject = this.state.baseObject;
    baseObject.notes = notes;
    this.setState({ baseObject });

    axios({
      method: 'post',
      url: url + 'Record',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
      data: baseObject,
    }).then(res => {
      let baseObject = res.data;
      let notes = res.data.notes;
      this.setState({ baseObject, notes });
    });
  }

  getRecord = id => {
    this.setState({isLoading: true});
    this.props.getRecordInput(id);
    this.setState({isLoading: false});
    // this.props.setActiveComponent('interface');
  }

  deleteRecordInstance = id => {
    if (this.state.openConfirmDelete == false) {
      this.setState({ openConfirmDelete: true, idToDelete: id });
    }
    if (this.state.openConfirmDelete == true) {
      this.props.deleteRecordInstance(id);
      this.setState({ openConfirmDelete: false, idToDelete: '' });
    }
  }

  checkProperty = field => {
    let fieldOrDescription;
    if (field !== 'status') {
      fieldOrDescription = field;
    } else if (field === 'status') {
      fieldOrDescription = `${field}.description`;
    }
    return fieldOrDescription;
  }

  render() {
    let columns = [];
    let { baseObject, notes } = this.state;

    if (this.props.baseObject !== undefined) {
      this.props.baseObject.records.map(col => {
        let arr = Object.keys(col);
        columns = arr.map(d => ({ Header: d, accessor: d }));
        let button = {
          Header: 'Select',
          accessor: 'id',
          filterable: false,
          Cell: row => (
            <Button.Group>
              { this.state.idToDelete !== row.original.id ? (
                <Button
                  row={row}
                  onClick={() =>  this.getRecord(row.original.id)}
                >
                  Select Event
                </Button>
              ) : (
                <Button
                row={row}
                onClick={() => this.setState({openConfirmDelete: false, idToDelete: ''})}
              >
                Cancel
              </Button>
              )}
              { this.props.profile.role.id === 1 || this.props.profile.role.id === 2 ? (
                <Button
                  row={row}
                  onClick={() => this.deleteRecordInstance( row.original.id )}
                  style={
                    row.original.id == this.state.idToDelete ? {backgroundColor: 'green', color: 'white'} : {backgroundColor: 'red', color: 'white'}
                  }
                  content={
                    this.state.openConfirmDelete == true ? 'Confirm' : 'Delete'
                  }
                />
              ) : null}
            </Button.Group>
          ),
        };

        let eventDate = {
          Header: 'Event Date',
          accessor: 'dateRecorded',
          filterable: false,
          Cell: row => (
            <Moment format="MM/DD/YYYY">{row.original.dateRecorded}</Moment>

          ),

        };

        let userRecorded = {
          Header: 'User Recorded',
          accessor: 'userRecorded',
        };

        columns = columns.filter(column => column.Header !== 'status');
        columns = columns.filter(column => column.Header !== 'inputData');
        columns = columns.filter(column => column.Header !== 'dateRecorded');
        columns = columns.filter(column => column.Header !== 'userRecorded');
        columns.push(eventDate, userRecorded, button);
      })
    }


    const Modal = (
      <EventsModals
        buttonText="Reminders"
        color="violet"
        notes={notes}
        icon="sticky note"
        label={{ as: 'a', basic: true, content: '1', labelPosition: 'right' }}
      />
    );

    const button = (
      <BaseObjectConsumer>
      {({
        startNewEventRecord
      }) => (
      <Button
        icon="add"
        content="New Event"
        labelPosition="left"
        onClick={startNewEventRecord}
        color="blue"
      />
      )}
      </BaseObjectConsumer>
    );



    return (
      <BaseObjectConsumer>
        {({ baseObject, updateSmartID, isLoading }) => (
          <React.Fragment>
            {baseObject === undefined || baseObject.records === undefined  || isLoading == true  || this.state.isLoading == true ? (
              <Segment style={{ height: '300px' }}>
                <Dimmer active size="massive">
                  <Loader style={{ marginTop: '10px' }} />
                </Dimmer>
              </Segment>
            ) : (
              <React.Fragment>
                <ParticipantHeader
                  participantID={ baseObject.id}
                  description={baseObject.status.description}
                  smartID={baseObject.smartID}
                  scramblerID={baseObject.scramblerID}
                  notes={baseObject.notes}
                  dateRecorded={baseObject.dateRecorded}
                  setActiveComponent={this.props.setActiveComponent}
                  setBaseActiveIndex={this.props.setBaseActiveIndex}
                  activeComponent={this.props.activeComponent}
                  updateSmartID={updateSmartID}
                  updateBaseNotes={this.updateBaseNotes}
                  showGraph={ this.props.recordType === 'Participant' }
                />
                <PlaceholderTable
                  columns={columns}
                  className="tblBaseRecord"
                  style={{
                    textAlign: 'center',
                    clear: 'right',
                    padding: '5px',
                  }}
                  data={
                    baseObject.records !== undefined
                      ? baseObject.records
                      : null
                  }
                  button={button}
                  recordCount={baseObject.records.length}
                />
              </React.Fragment>
            )}
            <ToastContainer autoClose={5000} />
          </React.Fragment>
        )}
      </BaseObjectConsumer>
    );
  }
}

BaseRecords.contextType = BaseObjectContext;
