import React from 'react';

const PointsContext = React.createContext();

export class PointsContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     points: 0,
     totalPoints: 0,
     changedPoints: 0
    };
  }

  componentDidMount() {
    if (this.props.values !== undefined) {
      let values = this.props.values;
      this.setState({
        points: values.points,
        totalPoints: values.totalPoints,
        changedPoints: values.changedPoints
      });
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values !== this.props.values) {
     let values = this.props.values;
     let points = prevProps.values.points += values.changedPoints;
     this.setState({points, totalPoints: values.totalPoints, changedPoints: values.changedPoints });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <PointsContext.Provider
        value={{
          points: this.state.points,
          totalPoints: this.state.totalPoints,
          changedPoints: this.state.changedPoints
        }}
      >
      {console.log(this.props.values)}
        {children}
      </PointsContext.Provider>
    );
  }
}

export const PointsContextConsumer = PointsContext.Consumer;
