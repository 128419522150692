import React from 'react';
import { Form, Header, Dropdown, Button, Icon } from 'semantic-ui-react';
import * as _ from 'lodash';


export class NewCommunitySID extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      sid: {
        countyCode: '',
        typeCode: '',
        specifyingCode: '',
      },
      smartID: '',
      formHeader: 'New Community ID',
    };
  }

  componentDidMount() {
    if(this.props.smartID !== undefined && this.props.lookup == false) {
      this.setState({
        smartID: this.props.smartID,
        formHeader: 'Update Community ID',
      });
    } else if (this.props.smartID == null && this.props.lookup == false) {
      this.setState({
        smartID: '',
        formHeader: 'New Community ID',
      })
    }
  }

  createSID = (event, { name, value }) => {
    const sid = { ...this.state.sid, [name]: value };
    this.setState({ sid });
    let concatSID = '';
    for (var key in sid) {
      concatSID = concatSID.concat(sid[key]).toUpperCase();
      if (concatSID.length >= 7 && this.props.lookup !== true) {
        this.props.toggleConfirmPost(true, concatSID);
      }
      if(concatSID.length >=7 && this.props.lookup !== false)  {
        this.props.toggleConfirmPost(false, concatSID);
      }
    }
    this.setState({ concatSID });
  }

  render() {
    const {smartID, concatSID, formHeader, sid} = this.state;
    return(
      <React.Fragment>
      {this.props.lookup == false ? (
        <Header dividing>
          <Icon name="add circle" />
          {formHeader}
        </Header>
      ) : null}
        <Form size="small" widths="equal">
          <div className="smartIDViz">
            <h3>{smartID && this.props.lookup == false ? `Current Community ID: ${smartID}` : null}</h3>
            <h3 style={{ display: 'inline-block' }}>{this.props.lookup == true ? null : concatSID ? `New Community ID: ${concatSID}` : null}</h3>
          </div>
          <div className="SmartIDForm">
            <Form.Group widths="equal">
              <Form.Input
                name="countyCode"
                label="2 Digit County Code"
                value={this.state.sid.countyCode}
                onChange={this.createSID}
                maxLength="2"
                autoComplete="off"
              />
              <Form.Input
                name="typeCode"
                label="4 Letter Type Code"
                value={this.state.sid.typeCode.toUpperCase()}
                onChange={this.createSID}
                maxLength="4"
                autoComplete="off"
              />
              <Form.Input
                name="specifyingCode"
                label="Specifying Code"
                value={this.state.sid.specifyingCode}
                onChange={this.createSID}
                maxLength="2"
                autoComplete="off"
              />
            </Form.Group>
          </div>
        </Form>
      </React.Fragment>
    )
  }

}
