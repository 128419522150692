import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import {
  Form,
  Radio,
  Checkbox,
  Input,
  Dropdown,
  TextArea,
  Label,
  Segment,
  Loader,
  Dimmer,
  Popup,
} from 'semantic-ui-react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';
import { integerKeyDown } from '../../Services/functions';

export function NotCollection(props) {
  let inputData = props.inputData;
  let element = props.element;
  let isReadOnly = props.isReadOnly;
  let baseObject = props.baseObject;
  let record = baseObject.records[0];
  let invalidFields = props.invalidFields;

  const [form, setValues] = useState({
    element: element,
    loading: true,
    valid: true,
  });

  const prevValueRef = useRef(form.element.value);
  const prevValue = prevValueRef.current;

  useEffect(() => {
    props.getPoints();
  }, [ props.record.inputData, props.baseObject.inputData ]);

  useEffect(() => {
    let inputCheck = _.concat(baseObject.inputData, record.inputData);
    inputCheck = _.flatMap(inputCheck);


    /* Check to see if the element is the date field and then formats it as a moment date object */
    let found = _.find(inputCheck, { elementID: 41 });
    if (found !== undefined && form.element.id == 41) {
      let element = form.element;
      let value = new Date(found.value);
      element.value = value;
    } else if (found == undefined && form.element.id == 41) {
      let element = form.element;
      let value = new Date();
      element.value = value;
    }

    /* Check to see if the element is find in existing record data */
    let find = _.find(inputCheck, { elementID: form.element.id });

    if (find !== undefined) {
      /*
         Checks to see if the element is found in the invalid fields array and sets the element to valid or invalid
         Triggers popup when field is invalid
      */

      prevValueRef.current = find.value;
      let findInvalid = _.find(invalidFields, { id: form.element.id });
      if (findInvalid !== undefined && form.valid == true) {
        setValues({ ...form, element, valid: false });
        props.showPopup(form.element.validation.map(v => v.message), form.valid);
      }

      if (findInvalid == undefined && form.valid == false) {
        setValues({ ...form, element, valid: true });
        props.showPopup(form.element.validation.map(v => v.message), form.valid);
      }

      /*  Checks optionID and sets the field value to an existing value if present */
      let optionID = find.optionID;
      if (optionID !== undefined && form.element.optionID !== find.optionID) {
        let element = form.element;
        element.optionID = optionID;
      }

      let value = find.value;

      if (value !== undefined || prevValueRef.current !== undefined) {
        let element = form.element;
        element.value = prevValueRef.current !== undefined ? prevValueRef.current : find.value;

        /* Sets the value of the ref to match the value stored in state */
        if (form.element.value !== find.value) {
          element.value = find.value;
          prevValueRef.current = element.value;
        }
      }
    }

    /* Sets the values into the state any time the form is in a loading state  */
    if (element !== undefined && form.loading == true) {
      if (form.element.value !== null && form.element.value !== undefined && form.element.value.length > 0) {
        prevValueRef.current = form.element.value
      }
      setValues({ ...form, element, loading: false });
    }
  });

  const updateDropdownField = (e, { value }) => {

    let element = form.element;
    element.optionID = value;
    setValues({
      ...form,
      element,
      set: true,
      blurred: false,
    });
    props.onChange(
      form.element.label,
      form.element.type,
      value,
      form.element.points,
      form.element.id,
      form.element.validation
    );
  };

  const updateDate = value => {
    let date = value;
    console.log( value )
    let element = form.element;
    element.value = date;
    props.onChange(
      form.element.label,
      form.element.type,
      date,
      form.element.points,
      form.element.id
    );
    setValues({ ...form, element });
  };

  const updateField = e => {
    let element = form.element;

    prevValueRef.current = e.target.value;

    element.value = prevValueRef.current;

    function handleBlur(e) {
      setValues({ ...form, element });

      props.onChange(
        form.element.label,
        form.element.type,
        prevValueRef.current,
        form.element.points,
        form.element.id,
        form.element.validation
      );
    }

    document
      .getElementById(form.element.id)
      .addEventListener('blur', handleBlur);

  };

  const showPopup = valid => {

    if (valid !== undefined && valid == true) {
      return false;
    }

    if (valid !== undefined && valid == false) {
      return true;
    }
  };

  const createQuestionsWithProps = () => {
    // const conditionElement = elements.options.map(cE => cE.conditionElement);
    // const conditionValues = elements.options.map(cV => cV.conditionValues);
    let elements = form.element;
    let type = form.element.type;

    switch (type) {
      case 4:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              control={Input}
              label={elements.label}
              seq={elements.seq !== undefined ? elements.seq : 0}
              width={16}
              onChange={isReadOnly == false ? updateDropdownField : null}
              validation={elements.validation}
              required={elements.validation.required == true ? true : false}
              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 5:
        let options = elements.options.map(o => ({
          key: o.id,
          text: o.label,
          value: o.id,
        }));

        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              label={elements.label}
              control={Dropdown}
              selection
              value={form.element.optionID || 0}
              width={16}
              seq={elements.seq}
              search
              options={[ { key: 0, text: 'Select an option', value: 0 }, ...options ]}
              tabIndex={ 0 }
              openOnFocus={ false }
              onChange={isReadOnly == false ? updateDropdownField : null}
              disabled={isReadOnly == true ? true : false}
              validation={elements.validation}
              required={elements.validation.required == true ? true : false}
              // conditionElement={conditionalOptions}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 6:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              control={Radio}
              label={elements.label}
              width={16}
              seq={elements.seq}
              onChange={isReadOnly == false ? updateDropdownField : null}
              disabled={isReadOnly == true ? true : false}
              validation={elements.validation}
              required={elements.validation.required == true ? true : false}
              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 7:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              control={Checkbox}
              width={16}
              label={elements.label}
              seq={elements.seq}
              onChange={isReadOnly == false ? updateDropdownField : null}
              disabled={isReadOnly == true ? true : false}
              validation={elements.validation}
              required={elements.validation.required == true ? true : false}
              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 8:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              control={Input}
              label={elements.label}
              defaultValue={prevValueRef.current}
              width={16}
              seq={elements.seq !== undefined ? elements.seq : 0}
              type="number"
              ref={prevValueRef}
              className={form.valid == true ? 'validField' : 'invalidField'}
              onChange={isReadOnly == false ? updateField : null}
              disabled={isReadOnly == true ? true : false}
              validation={elements.validation}
              onKeyDown={ integerKeyDown }
              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
            >
            </Form.Field>
          </div>
        );
      case 14:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Input key={_.uniqueId()} label={elements.label} required={elements.validation.required == true ? true : false}>
              <BaseObjectConsumer>
                {({ dateValue, onDateChange }) => (
                  <DatePicker
                    onChange={value =>
                      isReadOnly == false ? updateDate(value) : null
                    }
                    isClearable
                    selected={
                      form.element.value && form.element.value !== undefined
                        ? new Date( form.element.value )
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        keepCharPositions= {true}
                        guide = {true}
                      />
                    }
                    disabled={isReadOnly == true ? true : false}
                  />
                )}
              </BaseObjectConsumer>
            </Form.Input>
          </div>
        );
      case 15:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Input
              key={_.uniqueId()}
              name={elements.label}
              id={elements.id}
              points={elements.points}
              width={16}
              style={{ minHeight: 200 }}
              defaultValue={prevValueRef.current}
              ref={prevValueRef}
              control={TextArea}
              seq={elements.seq}
              onChange={isReadOnly == false ? updateField : null}
              disabled={isReadOnly == true ? true : false}
              label={elements.label}
              validation={elements.validation}
              required={elements.validation.required == true ? true : false}
            />
          </div>
        );
      case 17:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              width={16}
              seq={elements.seq}
              style={{ minHeight: 200 }}
              control={Label}
              label={elements.label}
              onChange={isReadOnly == false ? updateDropdownField : null}
              disabled={isReadOnly == true ? true : false}
              validation={elements.validation}
              required={elements.validation.required == true ? true : false}
            />
          </div>
        );
      default:
        return null;
    }
  };



  return (
    <React.Fragment>
      <Popup
        content={form.element.validation.map(v => v.message)}
        open={showPopup(form.valid)}
        trigger={createQuestionsWithProps()}
        position="top center"
        verticalOffset={-35}
      />
    </React.Fragment>
  );
}
