import React from 'react';
import { TabHeader } from '../TabHeader/TabHeader';
import PlaceholderTable from '../Tables/PlaceholderTable';
import { AdminModals } from '../Modals/AdminModals';
import { NavigationBar } from '../Interface/Interface';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';
import { Loader, Dimmer } from 'semantic-ui-react';
import { APIContextConsumer } from '../../Context/APIProgressContext';
export class UserAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      sites: [],
      siteOptions: [],
      isLoading: false,
      userRoleOptions: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }
    this.getUsers();
    this.getSites();
    this.makeRoleOptions();
  }

  getUsers = () => {
    this.setState({isLoading: true});
    this.props.updateCounter(1,0);
    axios({
      method: 'get',
      url: url + 'users',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.props.updateCounter(0,1);
      this.setState({ users: res.data, data: res.data, isLoading: false });
    });
  }

  getSites = () => {
    this.setState({isLoading: true});
    this.props.updateCounter(1,0);
    axios({
      method: 'get',
      url: url + 'sites',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.props.updateCounter(0,1);
      this.setState({ sites: res.data, isLoading: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sites !== prevState.sites) {
      this.makeOptions();
    }
    if (this.state.users.length !== prevState.users.length) {
      this.getUsers();
    }
  }

  makeOptions = () => {
    let siteOptions = this.state.sites.map(site => ({
      key: site.id,
      text: site.name,
      value: { id: site.id, name: site.name },
    }));


    this.setState({ siteOptions });
  }

  makeRoleOptions = () => {
    if(this.props.profile !== undefined) {
      let userRoleOptions;

      if (parseInt(this.props.profile.role.id) === 1) {
        userRoleOptions = [
          { key: 1, text: 'State Admin', value: { id: 1, name: 'Admin' } },
          { key: 2, text: 'Site Admin', value: { id: 2, name: 'Site Admin' } },
          { key: 3, text: 'User', value: { id: 3, name: 'User' } },
          {
            key: 4,
            text: 'User Read Only',
            value: { id: 4, name: 'User Read Only' },
          },
        ];
        this.setState({ userRoleOptions });
      } else {
        userRoleOptions = [
          { key: 3, text: 'User', value: { id: 3, name: 'User' } },
          {
            key: 4,
            text: 'User Read Only',
            value: { id: 4, name: 'User Read Only' },
          },
        ];
        this.setState({ userRoleOptions });
      };
    }
  }

  render() {
    const {data} = this.state;
    const columns = [
      { Header: 'Site', accessor: 'site.name' },
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      { Header: 'Date Created', accessor: 'createdOn', Cell: row => <span>{ new Date( row.original.createdOn.replace( 'Z', '' ) ).toLocaleDateString() }</span> },
      {
        Header: 'Modify User',
        accessor: 'id',
        filterable: false,
        Cell: row => (
          <APIContextConsumer>
            {({ updateCounter }) => (
              <AdminModals
                buttonText="Select User"
                color="grey"
                userRow={row}
                userID={row.row.id}
                siteOptions={this.state.siteOptions}
                userRoleOptions={this.state.userRoleOptions}
                getUsers={this.getUsers}
                users={this.state.users}
                updateCounter={updateCounter}
              />
            )}
          </APIContextConsumer>
        ),
      },
    ];

    const Modal = (
      <APIContextConsumer>
        {({ updateCounter }) => (
          <AdminModals
            buttonText="New User"
            color="violet"
            siteOptions={this.state.siteOptions}
            userRoleOptions={this.state.userRoleOptions}
            getUsers={this.getUsers}
            users={this.state.users}
            updateCounter={updateCounter}
          />
        )}
      </APIContextConsumer>
    );
    const content = (
      <PlaceholderTable
        className="tblUsers"
        style={{ textAlign: 'left', clear: 'right', padding: '5px' }}
        modal={Modal}
        columns={columns}
        data={data}
        recordCount={data !== undefined ? data.length : 0 }
      />
    );



    return (
      <div
        style={{
          background: '#f5f5f5',
          paddingTop: '1em',
          paddingLeft: '10px',
        }}
      >
        <Dimmer active={this.state.isLoading === true}>
          <Loader active={this.state.isLoading === true} />
        </Dimmer>
        <NavigationBar
          setBaseActiveIndex={this.props.setBaseActiveIndex}
          profile={this.props.profile}
        />
        <TabHeader titleLeft="USER ADMINISTRATION" />
        {this.props.render({ content: content })}
      </div>
    );
  }
}
