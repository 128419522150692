import React from 'react';
import { Button, Icon, List, TextArea, Loader } from 'semantic-ui-react';
import Moment from 'react-moment';
import { EditNotesModal } from '../Modals/NotesModal';

var moment = require('moment');

export class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const notes = this.props.notes;
    // this.setState({ notes });
  }

  componentDidUpdate(prevState, prevProps) {
    if (
      prevProps.notes !== undefined &&
      this.props.notes.length > prevProps.notes.length
    ) {
      this.setState({ newNote: '' });
    }
  }

  toggleNotes = notesDisplay => {
    if (notesDisplay === 'hide') {
      this.setState({ display: 'none' });
    } else if (notesDisplay === 'show') {
      this.setState({ display: 'initial' });
    }
  }

  render() {
    const { notes } = this.props;
    const styles = {
      listItemDismissed: {
        textDecoration: 'line-through',
        display: this.state.display,
      },
      listItemActive: {
        textDecoration: 'none',
      },
    };
    return (
      <React.Fragment>
        {notes !== undefined ? (
          <List verticalAlign="middle" celled ordered selection>
            {this.props.notes.map(note => (
              <List.Item
                key={note.id}
                style={
                  note.notificationStatus === 12
                    ? styles.listItemActive
                    : styles.listItemDismissed
                }
                content={
                  <span>
                    <h4 style={{}}>
                      <Moment format="MM/DD/YYYY">{note.dateRecorded}</Moment>
                    </h4>
                    <p>
                      {note.content !== null && note.content !== undefined
                        ? note.content.split('.', 1)
                        : 'No Content'}{' '}
                      ...
                      <EditNotesModal
                        note={note}
                        addUpdateBaseNotes={this.props.addUpdateBaseNotes}
                      />
                    </p>
                  </span>
                }
              />
            ))}
          </List>
        ) : (
          <Loader />
        )}
        <TextArea
          placeholder="Add participant note here..."
          autoHeight
          value={this.state.newNote}
          style={{
            width: '100%',
            marginLeft: 'auto',
            right: 'auto',
            padding: '0.5em',
          }}
          onInput={(event, { value }) => this.setState({ newNote: value })}
        />
        <br />
        <Button
          icon="plus"
          text="Add Note"
          color="violet"
          fluid
          style={{
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1em',
          }}
          onClick={() =>
            this.props.addUpdateBaseNotes('add', this.state.newNote)
          }
        />
      </React.Fragment>
    );
  }
}
