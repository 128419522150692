import React from 'react';
import { TabHeader } from '../TabHeader/TabHeader';
import Base from './Base';
import { BaseRecords } from './BaseRecords';
import { Button } from 'semantic-ui-react';
import { Interface } from '../Interface/Interface';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';

export class Events extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if(this.props.baseObject !== undefined) {
      this.props.getRecords();
    }
  }


  renderRightHeader = () => {
    if (this.props.profile !== null && this.props.profile !== undefined) {
      return (
        <h3 style={{ marginRight: '20px', fontWeight: 200 }}>
          {this.props.profile.site.name}
        </h3>
      );
    } else {
      return (
        <h3 style={{ marginRight: '20px', fontWeight: 200 }}>
          No Site Assigned
        </h3>
      );
    }
  };

  renderLeftTitle = () => {
    if (this.props.activeComponent === 'base' && this.props.recordType !== undefined) {
      return `${this.props.recordType} Events`;
    } else {
      return "SSP Events";
    }
  }


  render() {
    const { profile } = this.props;

    const rightHeader = this.renderRightHeader();
    const titleLeft = this.renderLeftTitle();
    const activeComponent = this.props.activeComponent;

    const baseRecord = (
      <BaseObjectConsumer>
        {({ baseObject, postNewParticipant, getRecordInput, deleteRecord, getRecords }) =>
          <Base
            getRecords={getRecords}
            setActiveComponent={this.props.setActiveComponent}
            recordID={baseObject !== undefined ? baseObject.id : undefined}
            recordType={this.props.recordType}
            getRecordInput={this.props.getRecordInput}
            setBaseActiveIndex={this.props.setBaseActiveIndex}
            activeComponent={this.props.activeComponent}
            updateBaseNotes={this.props.updateBaseNotes}
            updateSmartID={this.props.updateSmartID}
            profile={this.props.profile}
            deleteRecord={deleteRecord}
            postNewParticipant={postNewParticipant}
            baseObject={baseObject}
          />
        }
      </BaseObjectConsumer>
    );
    const baseRecords = (
      <BaseObjectConsumer>
        {({ baseObject, startNewEventRecord, getRecordInput, getRecords, deleteRecordInstance, records }) =>
          <BaseRecords
            setActiveComponent={this.props.setActiveComponent}
            recordID={baseObject !== undefined ? baseObject.id : undefined}
            recordType={this.props.recordType}
            getRecordInput={this.props.getRecordInput}
            setBaseActiveIndex={this.props.setBaseActiveIndex}
            activeComponent={this.props.activeComponent}
            updateBaseNotes={this.props.updateBaseNotes}
            updateSmartID={this.props.updateSmartID}
            profile={this.props.profile}
            startNewEventRecord={startNewEventRecord}
            getRecordInput={getRecordInput}
            getRecords={getRecords}
            baseObject={baseObject}
            deleteRecordInstance={deleteRecordInstance}
            records={records}
          />
        }
      </BaseObjectConsumer>
    );

    return (
      <div>
        <TabHeader titleLeft={titleLeft} titleRight={rightHeader} />
        {this.props.render({
          activeComponent,
          baseRecord,
          baseRecords,
        })}
      </div>
    );
  }
}
