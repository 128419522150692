import React from 'react';
import { Tab, Responsive } from 'semantic-ui-react';
import { SidebarPanes, tabletSidebarPanes } from './SidebarPanes';

export class Sidebar extends React.Component {
  handleChange = (event, data) => {
    this.props.setBaseActiveIndex('eventTypeCheck', data.activeIndex);
  }

  componentDidMount() {
    if (this.props.width !== undefined) {
      if (this.props.width < 992) {
        this.props.setBaseActiveIndex('eventTypeCheck', 0);
      }
    }
  }

  render() {
    const { profile } = this.props;
    return (
      <React.Fragment>
        { profile !== undefined
          ? <React.Fragment>
              <Responsive
                as={Tab}
                menu={{
                  className: `mainMenu${
                    profile !== undefined ? profile.role.id : null
                  }`,
                  secondary: true,
                  attached: true,
                  style: {
                    backgroundColor: 'rgba(61, 30, 114, 0.75)',
                    width: '101%',
                  },
                }}
                grid={{ paneWidth: 13, tabWidth: 3 }}
                panes={SidebarPanes(this.props)}
                renderActiveOnly={true}
                activeComponent={this.props.activeComponent}
                setActiveComponent={this.props.setActiveComponent}
                setBaseActiveIndex={this.props.setBaseActiveIndex}
                recordID={this.props.recordID}
                recordType={this.props.recordType}
                getRecordInput={this.props.getRecordInput}
                activeIndex={this.props.activeBaseIndex}
                onTabChange={this.handleChange}
                profile={this.props.profile}
                updateBaseNotes={this.props.updateBaseNotes}
                updateSmartID={this.props.updateSmartID}
                baseObject={this.props.baseObject}
                width={this.props.width}
              />
              <Responsive
                as={Tab}
                menu={{
                  className: 'mainMenu',
                  secondary: true,
                  attached: true,
                  style: {
                    backgroundColor: 'rgba(61, 30, 114, 0.75)',
                    width: '101%',
                  },
                }}
                grid={{ paneWidth: 13, tabWidth: 3 }}
                panes={tabletSidebarPanes(this.props)}
                renderActiveOnly={true}
                activeComponent={this.props.activeComponent}
                setActiveComponent={this.props.setActiveComponent}
                setBaseActiveIndex={this.props.setBaseActiveIndex}
                recordID={this.props.recordID}
                recordType={this.props.recordType}
                getRecordInput={this.props.getRecordInput}
                activeIndex={this.props.activeBaseIndex}
                onTabChange={this.handleChange}
                profile={this.props.profile}
                updateBaseNotes={this.props.updateBaseNotes}
                updateSmartID={this.props.updateSmartID}
                baseObject={this.props.baseObject}
                width={this.props.width}
                {...Responsive.onlyTablet}
              />
            </React.Fragment>
          : null
        }
      </React.Fragment>
    );
  }
}
