import React from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';

const PasswordConfirm = ({ open, context, password, confirm }) => {
  return (
    <Popup
      open={ open }
      context={ context }
      size="small"
      position="top center"
    >
      <Popup.Content>
        <List>
          <List.Content>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                size={ confirm && password === confirm ? null : 'mini' }
                color={ confirm && password === confirm ? 'green' : null }
                name={ confirm && password === confirm ? 'check' : 'circle' }
              />
              &nbsp;
              Passwords must match
            </List.Item>
          </List.Content>
        </List>
      </Popup.Content>
    </Popup>
  );
}

export default PasswordConfirm;
