import React from 'react';
import axios from 'axios';
import { key, url, getCookie } from '../Util';
import { PointsContextProvider } from './PointsContext';
import { AIContextProvider } from './AIContext';
import AppLogout from '../Containers/LogoutTimeout';
import moment from 'moment';
import _ from 'lodash';

export const BaseObjectContext = React.createContext();

/*
  invalidFields state object holds fields as Objects as {id: INT, invalid: Boolean}

  validateField(fieldid, fieldvalue, fieldReqs)
    -takes in elementID, value, and requirments, then checks if value
    meets validation requirments
    -if valid, pushed to baseObject
    -if invalid:
       -not added to baseObject
       -pushed to [invalidFields] in state
       -error prop on field will be set to true
*/

export class BaseObjectProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { points: 0, aiTasks: [], invalidFields: [] };
  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }

    if (this.props.baseObject !== undefined) {
      let baseObject = this.props.values.baseObject;

      this.setState({ baseObject, instanceID: "",  points: 0 });
    }
  }

  validateField = (elementID, value, requirements) => {
    let invalidFields = this.state.invalidFields;
    let find = _.find(invalidFields, { id: elementID });
    if (requirements !== undefined) {
      let validation = requirements.map(req => req.regEx);
      if (validation.length > 0) {
        validation.map(v => {
          let expression = new RegExp(v);
          let valid = expression.test(value);
          let invalidField = { id: elementID, valid: valid };
          if (find == undefined) {
            if (valid == false) {
              invalidFields.push(invalidField);
              this.setState({ invalidFields });
            }
          }

          if (find !== undefined) {
            if (valid !== false) {
              let index = _.findIndex(invalidFields, elementID);
              invalidFields.splice(index, 1);
              this.setState({ invalidFields });
            }
          }
        });
      }
    }
  }



  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.baseObject !== undefined &&
      this.state.inputData == undefined
    ) {
      let baseInputData = this.state.baseObject.inputData;
      let instanceID = this.state.instanceID;
      let records = this.state.baseObject.records;
      let record = _.find(records, { id: instanceID });
      let recordInput = record !== undefined ? record.inputData : [];
      let inputData = _.concat(baseInputData, recordInput);
      this.setState({ inputData, records, record, recordInput });
    }

    if (
      prevProps.values !== undefined &&
      prevProps.values.baseObject !== this.props.values.baseObject
    ) {
      this.setState({ baseObject: this.props.values.baseObject });
    }


    if (prevState.baseObject !== this.state.baseObject) {
      let baseInputData = this.state.baseObject.inputData;
      let instanceID = this.state.instanceID;
      let records = this.state.baseObject.records;
      let record = _.find(records, { id: instanceID });
      let recordInput = record !== undefined ? record.inputData : [];
      let inputData = _.concat(this.state.baseObject.inputData, this.state.baseObject.records.inputData);
      let baseObject = this.state.baseObject;
      this.setState({ inputData, records, record, recordInput, baseObject });
    }

    if (
      prevState.baseObject !== undefined  &&
      this.state.baseObject.records !== undefined &&
      prevState.baseObject.records.length < this.state.baseObject.records.length
    ) {
      this.setState({isLoading: true})
      // this.getRecords();
    }

    if(prevProps.values !== undefined &&prevProps.values.activeComponent !== this.props.values.activeComponent) {
      if(this.props.values.activeComponent == 'base' ) {
        // this.setState({baseObject: {}, instanceID: "" })
      }
    }

    if (prevProps.baseObject !== this.props.baseObject) {
      this.setState({ baseObject: this.state.baseObject });
    }

    if (
      this.state.baseObject !== undefined &&
      prevState.baseObject !== undefined &&
      _.isEmpty(this.state.baseObject) == false &&
      prevState.baseObject.records !== undefined &&
      this.state.baseObject.records[0] !== prevState.baseObject.records[0]
    ) {
      if(this.state.baseObject.records.length > 0) {
        let inputData = _.concat(this.state.baseObject.inputData, this.state.baseObject.records[0].inputData);
        this.setState({ baseObject: this.state.baseObject , inputData});
      }
    }
  }



  postNewParticipant = async(sid, type) => {
    if (sid !== undefined && sid.length >= 11 && type == 'Participant') {
      this.props.updateCounter(1, 0);
      let smartID = sid.toString();
      let newParticipant = {
        id: 0,
        type: 18,
        scramblerID: null,
        smartID: smartID,
        historyID: '',
        dateRecorded: new Date(),
        status: {},
        inputData: [],
        records: [],
        notes: [],
      };

      axios({
        method: 'post',
        url: url + 'Record',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        data: newParticipant,
      }).then( res => {
        let baseObject = res.data;
        this.setState({ baseObject: res.data });
        this.props.updateCounter(0, 1);
        this.startNewEventRecord(baseObject);
      });
    }

    if (sid !== undefined && sid.length >= 7 && type == 'Community') {

      this.props.updateCounter(1, 0);
      let communityID = sid.toString();

      let newCommunity = {
        id: 0,
        type: 19,
        scramblerID: null,
        smartID: communityID,
        historyID: '',
        dateRecorded: new Date(),
        status: {},
        inputData: [],
        records: [],
        notes: [],
      };

      axios({
        method: 'post',
        url: url + 'Record',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        data: newCommunity,
      }).then(  res => {
        let baseObject = res.data;
         this.setState({ baseObject: res.data });
        this.props.updateCounter(0, 1);
        this.startNewEventRecord(baseObject);
      });
    }
  }

  startNewEventRecord = (baseObject) => {
    if (baseObject !== undefined) {
      this.props.updateCounter(1, 0);

      let newRecordInstance = {
        id: 0,
        inputData: [],
        dateRecorded: new Date(),
        status: {
          id: 0,
          description: null,
        },
        userRecorded: '',
      };

      // this.setState(state => {
      //   const baseObject  = state.baseObject;
      //   baseObject.records =  [...state.baseObject.records, newRecordInstance];
      //     return {baseObject}
      // })

      const baseObject  = this.state.baseObject;
      baseObject.records = [...baseObject.records, newRecordInstance];

      axios({
        method: 'post',
        url: url + 'Record',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        data: baseObject,
      }).then(async res => {
        await this.setState({
          baseObject: res.data,
          instanceID: res.data.records[res.data.records.length - 1].id,
        });
        this.props.updateCounter(0, 1);
        this.getRecordInput(res.data.records[res.data.records.length - 1].id);
      });
    }
  }

  postEventRecord = () => {
    this.props.updateCounter(1, 0);
    let baseObject = this.state.baseObject;
    baseObject.records[0].dateRecorded = new Date();
    axios({
      method: 'post',
      url: url + 'Record',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
      data: baseObject,
    }).then(async res => {
     await this.setState({ baseObject: res.data, dateValue: {}, instanceID: null });
      this.props.updateCounter(0, 1);
      this.getRecords();
    });
  }

  getRecords = async (recordID = this.state.baseObject.id) => {
    this.setState({isLoading: true});
    // this.props.updateCounter(1, 0);
    const res = await axios({
      method: 'get',
      url: url + 'Record/' + recordID,
      headers: {
        api_key: key,
        AccessToken: this.props.token,
      },
    })
    this.setState({ baseObject: res.data, records: res.data.records, points: 0, isLoading: false});
  }

  getRecordInput = id => {
    this.props.updateCounter(1, 0);
    this.setState({isLoading: true})
    axios({
      method: 'get',
      url: url + 'Record/Instance/' + id,
      headers: {
        api_key: key,
        AccessToken: this.props.token,
      },
    }).then(async res => {
      let baseInput = res.data.inputData;
      let recordInputs = res.data.records[0].inputData;
      let inputData = _.concat(baseInput, recordInputs);

     await this.setState({
        instanceID: id,
        baseObject: res.data,
        record: res.data.records[0],
        records: res.data.records,
        inputData,
        points: 0,
      });
      this.props.updateCounter(0, 1);
      this.setState({isLoading: false});
      this.props.values.setActiveComponent('interface');
    });
  }

  onDateChange = (value, id, seq) => {
    let baseObject = this.state.baseObject;
    let record = this.state.record;
    let findRecord = _.find(record.inputData, { elementID: id });
    let findInstance = _.find(baseObject.records, { id: this.state.instanceID });
    const date = value._d.toUTCString();
    if (findRecord !== undefined) {
    } else {
      let newDate = {
        elementID: id,
        optionID: 0,
        value: date,
        seq: 0,
      };
      record.inputData = _.concat(record.inputData, newDate);
      findInstance = record;
      this.setState({ record, baseObject, dateValue: value });
    }
  }

  handleCollectionSubmission = (
    modalType,
    collectionValues,
    activeComponent,
    row,
    index
  ) => {
    let baseObject = this.state.baseObject;
    let instanceID = this.state.instanceID;
    let inputData = this.state.inputData;
    let record = _.find(baseObject.records, { id: instanceID });

    if (modalType == 'add') {
      row.map((value, index) => {
        let newInputData = {
          elementID: value.elementID,
          seq: value.seq,
          optionID: value.optionID,
          value: value.value
        }

        this.setState(state => {
          const inputData = [...state.inputData, newInputData];
          baseObject.records[0].inputData = [
            ...state.baseObject.records[0].inputData,
            newInputData,
          ];
          return { inputData, baseObject };
        });
      });
    }

    if (modalType == 'edit') {
      row.map(value => {
        let findFilter = _.filter(inputData, { elementID: value.elementID });
        let foundFilter = _.filter(baseObject.records[0].inputData, {
          elementID: value.elementID,
        });

          if (findFilter !== undefined) {
            let find = _.find(findFilter, {seq: value.seq});
            find.optionID = value.optionID;
            find.value = value.value;
            this.setState(state => {
              const inputData = state.inputData.map(val => {

                if(val.elementID == find.elementID && val.seq == find.seq) {
                  find = val;
                }
                return val;
              });
              return { inputData };
            });

          if(foundFilter !== undefined) {
            let found = _.find(foundFilter, {seq: value.seq});
            found.optionID = value.optionID;
            found.value = value.value;
            console.log(found)
              this.setState(state => {
                const baseObject = state.baseObject;
                baseObject.records[0].inputData = baseObject.records[0].inputData.map(
                  val => {
                    if(val.elementID == found.elementID && val.seq == found.seq) {
                      val = found;
                    }
                    return val;
                  }
                );
                return { baseObject };
              });
            }
          }


      });
    }
  }

  onChange = (name, type, value, points, elementid, validation) => {
    let inputData = this.state.inputData;
    let baseObject = this.state.baseObject;
    let foundValue = _.find(baseObject.records[0].inputData, { elementID: elementid });
    let findBase = _.find(this.state.baseObject.inputData, {
      elementID: elementid,
    });

    this.validateField(elementid, value, validation);

    // if (
    //   points !== undefined &&
    //   foundValue == undefined &&
    //   findBase == undefined
    // ) {
    //   let statePoints = this.state.points;
    //   let pointValue = type == 5 && value == 0 ? statePoints : (statePoints += points);
    //   this.setState({ points: pointValue });
    // }

    let newElement = {
      elementID: elementid,
      optionID: _.isInteger(value) ? value  : 0,
      seq: 0,
      value: !_.isInteger(value) ? value : ""
    }

    if(elementid <= 8 || elementid == 38) {
      if(findBase == undefined) {
        /* this sets the baseObject input data it is not in the array  set  */
      this.setState(state => {
        const baseObject = state.baseObject;
        baseObject.inputData = _.concat(state.baseObject.inputData, newElement);
          return {baseObject};
        });
      } else {
        if(findBase !== undefined) {
          /* this sets the baseObject input data it IS in the array  set  */
           this.setState(state => {
             const baseObject = state.baseObject;
             baseObject.inputData = baseObject.inputData.map((data, index) => {
               if(data == findBase) {
                 data = newElement;
                 return data
               } else {
                 return data
               }
             });
              return {baseObject}
           });
        }
      }
    }
    if(elementid >= 9 && elementid !== 38 ) {
      /* this sets the baseObject.records[0].inputData if the value is not in the record already */
      if(foundValue == undefined) {
        this.setState(state => {
          const baseObject = state.baseObject;
          baseObject.records[0].inputData = _.concat(state.baseObject.records[0].inputData, newElement);
          return {baseObject}
        });
      } else {
        if(foundValue !== undefined) {
          /* this sets the baseObject.records[0].inputData if the value IS  in the record already */
          this.setState(state =>  {
            const baseObject = state.baseObject;
            baseObject.records[0].inputData = baseObject.records[0].inputData.map(data => {
              if(data == foundValue) {
                data = newElement;
                return data;
              } else {
                return data;
              }
            });
              return {baseObject}
          });
        }
      }
    }
/* end of function  */

  }


  removeCollectionElements = (row, confirmDelete) => {
    let baseObject = this.state.baseObject;
    if (confirmDelete === true) {


      for(let [key, value] of Object.entries(row)) {

        this.setState(state => {
          const baseObject = state.baseObject;
          let inputData = state.inputData;
          let sequenceToCompair = value.seq;
          let filter = baseObject.records[0].inputData.filter(item => item.seq == value.seq);

          if(_.isEmpty(value.value) == true && filter == undefined || _.isEmpty(filter) == true) {
            filter = baseObject.records[0].inputData.filter(item => item.optionID == value.optionID);
          } else if (filter == undefined) {
            filter = baseObject.records[0].inputData.filter(item => item.value == value.value);
          }
          let foundValue = _.find(filter, {elementID: value.elementID});

          let difference = _.without(baseObject.records[0].inputData, foundValue);

           difference.filter(item => {
            if(item.seq !== value.seq && item.elementID == value.elementID) {
              if(item.seq > sequenceToCompair) {
               item.seq = item.seq -1;
               return item;
              }
            }
          });

          baseObject.records[0].inputData = difference;
          inputData = _.concat(baseObject.inputData, baseObject.records[0].inputData)
          return {baseObject, inputData}
        });
      }
    }
  }

  addUpdateBaseNotes = (functionType, contentType) => {
    this.props.updateCounter(1, 0);
    let addNote = newNote => {
      let baseObject = this.state.baseObject;

      let notes = baseObject.notes;

      let noteToAdd = {
        dateRecorded: new moment().format('MM/DD/YYYY'),
        content: newNote,
        notificationStatus: 12,
      };

      this.setState(state => {
        const baseObject = state.baseObject;
        baseObject.notes = _.concat(baseObject.notes, noteToAdd);
         return {baseObject}
      });
      axios({
        method: 'post',
        url: url + 'Record',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        data: this.state.baseObject,
      }).then(async res => {
        this.props.updateCounter(0, 1);
        await this.setState({ baseObject: res.data });
      });
    };

    let changeNoteStatus = updatedNote => {
      let baseObject = this.state.baseObject;
      let baseNote = _.find(baseObject.notes, { id: updatedNote.id });

      if (baseNote.notificationStatus == 12) {
        this.props.updateCounter(1, 0);
        baseNote.notificationStatus = 13;
        this.setState({ baseObject });
        axios({
          method: 'post',
          url: url + 'Record',
          headers: {
            api_key: key,
            AccessToken: getCookie('token'),
          },
          data: this.state.baseObject,
        }).then(res => this.props.updateCounter(0, 1));
      } else if (baseNote.notificationStatus == 13) {
        this.props.updateCounter(1, 0);
        baseNote.notificationStatus = 12;
        this.setState({ baseObject });
        axios({
          method: 'post',
          url: url + 'Record',
          headers: {
            api_key: key,
            AccessToken: getCookie('token'),
          },
          data: this.state.baseObject,
        }).then(res => this.props.updateCounter(0, 1));
      }
    };

    if (functionType == 'add') {
      addNote(contentType);
    } else if (functionType == 'changeStatus') {
      changeNoteStatus(contentType);
    } else {
      console.log('problem in BaseObjectContext addUpdateFunction');
    }
  }

  updateSmartID = updatedSmartID => {

    let baseObject = this.state.baseObject;
    baseObject.smartID = updatedSmartID;
    this.props.updateCounter(1, 0);
    axios({
      method: 'post',
      url: url + 'Record',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
      data: baseObject,
    }).then(res => {
      this.props.updateCounter(1, 1);
      axios({
        method: 'get',
        url: url + 'Record/' + baseObject.id,
        headers: {
          api_key: key,
          AccessToken: this.props.token,
        },
      }).then(res => {
        let baseObj = res.data;
        this.setState({ baseObject: baseObj, records: baseObj.records });
        this.props.updateCounter(0, 1);
      });
    });

    this.setState({ baseObject });
  }

  deleteRecord = async id => {
    this.props.updateCounter(1, 0);
    const { data } = await axios({
      method: 'delete',
      url: url + 'Record/' + id,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    });
    this.props.updateCounter(0, 1);
    return data;
  }

  deleteRecordInstance = id => {
    this.props.updateCounter(1, 0);
    axios({
      method: 'delete',
      url: url + 'Record/Instance/' + id,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.getRecords();
      this.props.updateCounter(0, 1);
    });
  }

  render() {
    const { children } = this.props;
    return (
      <BaseObjectContext.Provider
        value={{
          baseObject: this.state.baseObject,
          inputData: this.state.inputData,
          records: this.state.records,
          record: this.state.record,
          recordInput: this.state.recordInput,
          onChange: this.onChange,
          handleCollectionSubmission: this.handleCollectionSubmission,
          checkBaseObjectRecords: this.checkBaseObjectRecords,
          startNewEventRecord: this.startNewEventRecord,
          getRecordInput: this.getRecordInput,
          postNewParticipant: this.postNewParticipant,
          points: this.state.points,
          onDateChange: this.onDateChange,
          dateValue: this.state.dateValue,
          getRecords: this.getRecords,
          removeCollectionElements: this.removeCollectionElements,
          addUpdateBaseNotes: this.addUpdateBaseNotes,
          updateSmartID: this.updateSmartID,
          postEventRecord: this.postEventRecord,
          invalidFields: this.state.invalidFields,
          deleteRecordInstance: this.deleteRecordInstance,
          deleteRecord: this.deleteRecord,
          isLoading: this.state.isLoading
        }}
      >
        <PointsContextProvider points={this.state.points}>
          <AIContextProvider aiTasks={this.state.aiTasks}>
            { console.log( this.state )}
            {children}
          </AIContextProvider>
        </PointsContextProvider>
      </BaseObjectContext.Provider>
    );
  }
}

export const BaseObjectConsumer = AppLogout( BaseObjectContext.Consumer );
