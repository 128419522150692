import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { UserForm } from '../Forms/UserForm';
import { SiteForm } from '../Forms/SiteForm';
import axios from 'axios';
import { Form, Header, Dropdown } from 'semantic-ui-react';
import btoa from 'btoa';
import { key, url, getCookie, validateEmail, validatePhone } from '../../Util';
import { NotificationForm } from '../Forms/NotificationForm';
import { ProviderForm } from '../Forms/ProviderForm';
import DuplicateUsernameModal from '../Admin/DuplicateUsernameModal';
import PasswordInstructionsError from '../Login/PasswordInstructionsError';
import RequiredFieldsError from '../Login/RequiredFieldsError';
import ProviderError from './ProviderError';
import SiteError from './SiteError';
import DuplicateSiteNameModal from '../Admin/DuplicateSiteNameModal';

export class AdminModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      site: {},
      user: {},
      notification: {},
      newUser: { id: 0 },
      newSite: { id: 0 },
      newNotification: { id: '', content: '', notificationStatus: 12 },
      resetSuccess: {},
      provider: { providerTypes: [] },
      duplicateUsernameModal: false,
      passwordInstructionsErrorOpen: false,
      userRequiredFieldsErrorOpen: false,
      newUserRequiredFieldsErrorOpen: false,
      providerErrorOpen: false,
      duplicateSiteNameModal: false,
      siteErrorOpen: false,
      newSiteErrorOpen: false
      // resetPassNotificationModal: false
    };
  }

  componentDidMount() {
      let token = getCookie('token');
      this.setState({ token });
  }


  handleModal = () => {
    const {userID, siteID, notificationID, providerID} = this.props;
    siteID !== undefined ? this.setSite(siteID) :
    userID !== undefined ? this.getUser(userID) :
    notificationID !== undefined ? this.getNotification(notificationID) :
    providerID !== undefined ? this.getProvider(providerID) :
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({ newUser: {}, newSite: {}, provider: { providerTypes: [] }, open: false, resetSuccess: {}});
  }

  handleSubmit = async () => {
    switch (this.props.buttonText) {
      case 'New User':
        let newUser = this.state.newUser;
        if ( newUser && newUser.firstName && newUser.lastName && newUser.userName && validateEmail( newUser.email ) && validatePhone( newUser.phone ) && newUser.role && newUser.password && newUser.site ) {
          if ( /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!#$%^&*()_=+[\]{}\\|;:’“,<.>/?-])(?:.){8,}$/.test( this.state.newUser.password ) ) {
            newUser.id = 0;
            const newUserPosted = await this.submitUser(newUser);
            if ( newUserPosted ) {
              this.setState({ newUser: {}, open: false });
              this.props.getUsers();
            }
          } else {
            this.setState({ passwordInstructionsErrorOpen: true });
          }
        } else {
          this.setState({ newUserRequiredFieldsErrorOpen: true });
        }
        break;
      case 'Select User':
        let { user } = this.state;
        if ( user && user.firstName && user.lastName && user.userName && validateEmail( user.email ) && validatePhone( user.phone ) && user.role && user.site ) {
          const userPosted = await this.submitUser(user);
          if ( userPosted ) {
            this.setState({ open: false, resetSuccess: {} });
            this.props.getUsers();
          }
        } else {
          this.setState({ userRequiredFieldsErrorOpen: true });
        }
        break;
      case 'Select Site':
        let { site } = this.state;
        let siteIsValid = site.name;
        if ( siteIsValid ) {
          const sitePosted = await this.submitSite( this.state.site );
          if ( sitePosted ) {
            this.setState({ open: false });
          }
        } else {
          this.setState({ siteErrorOpen: true });
        }
        break;
      case 'New Site':
        let { newSite } = this.state;
        let newSiteIsValid = newSite.name;
        if ( newSiteIsValid ) {
          const newSitePosted = await this.submitSite(this.state.newSite);
          if ( newSitePosted ) {
            this.setState({ newSite: {}, open: false });
          }
        }
        break;
      case 'New Notification':
        this.submitNotification(this.state.newNotification);
        this.setState({
          open: false,
          newNotification: { id: '', content: '', notificationStatus: 12 },
        });
        break;
      case 'Select Notification':
        this.submitNotification(this.state.notification);
        this.setState({ open: false });

        break;
      case 'New Provider':
        let newProvider = this.state.provider;
        let newProviderIsValid = newProvider.name
          && newProvider.description
          && newProvider.contactName
          && validateEmail( newProvider.contactEmail )
          && validatePhone( newProvider.contactPhone )
          && newProvider.login
          && newProvider.providerTypes.length;
        if ( newProviderIsValid ) {
          const newProviderPosted = await this.props.postProvider(this.state.provider, true);
          if ( newProviderPosted ) {
            this.setState({ provider: { providerTypes: [] }, open: false });
          }
        } else {
          this.setState({ providerErrorOpen: true });
        }
        break;
      case 'Select Provider':
        let { provider } = this.state;
        let providerIsValid = provider.name
          && provider.description
          && provider.contactName
          && validateEmail( provider.contactEmail )
          && validatePhone( provider.contactPhone )
          && provider.login
          && provider.providerTypes.length;
        if ( providerIsValid ) {
          const providerPosted = await this.props.postProvider(this.state.provider);
          if ( providerPosted ) {
            this.setState({ open: false });
          }
        } else {
          this.setState({ providerErrorOpen: true });
        }
        break;
      default:
        this.setState({ open: false, resetSuccess: {} });
    }
  }

  handleUserChange = (event, { name, value }) => {
    let user = { ...this.state.user, [name]: value };
    this.setState({ user });
  }

  handleUserPhoneChange = value => {
    let phone = value;
    let user = { ...this.state.user, phone };
    this.setState({ user });
  }

  handleSiteChange = (event, { name, value }) => {
    if (name === undefined || value === undefined) {
      const site = { ...this.state.site, contactPhone: event };
      this.setState({ site });
    } else {
      let site = { ...this.state.site, [name]: value };
      this.setState({ site });
    }
  }

  handleNewSite = (event, { name, value }) => {
    if (name === undefined || value === undefined) {
      const site = { ...this.state.newSite, contactPhone: event };
      this.setState({ newSite: site });
    } else {
      let newSite = { ...this.state.newSite, [name]: value };
      this.setState({ newSite });
    }
  }

  handleNewUser = (event, { name, value }) => {
    let newUser = { ...this.state.newUser, [name]: value };
    this.setState({ newUser });
  }

  handleNewUserPhone = value => {
    let phone = value;
    if (phone.length >= 14) {
      let newUser = { ...this.state.newUser, phone: phone };
      this.setState({ newUser });
    }
  }

  handleNewNotification = (event, { name, value }) => {
    let notification = { ...this.state.newNotification, [name]: value };
    this.setState({ newNotification: notification });
  }

  handleNotificationChange = (event, { name, value }) => {
    if (name == 'notificationStatus' && value == 20) {
      value = 12;
      let notification = { ...this.state.notification, [name]: value };
      this.setState({ notification });
    } else if (name == 'notificationStatus' && value != 20) {
      value = 20;
      let notification = { ...this.state.notification, [name]: value };
      this.setState({ notification });
    } else {
      let notification = { ...this.state.notification, [name]: value };
      this.setState({ notification });
    }
  }

  getProvider = id => {
    if (id !== undefined) {
      axios({
        method: 'get',
        url: url + 'provider/' + id,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      }).then(res => {
        this.setState({ provider: res.data, open: true });
      });
    } else {
      this.setState({ provider: { providerTypes: [] }, open: true });
    }
  }

  handleProviderChange = (event, { name, value }) => {
      const provider = { ...this.state.provider, [name]: value };
      this.setState({ provider });
  }

  handleProviderTypeChange = (e, {value}) => {
    const providerTypes = value.map( providerType => ({ id: providerType }) );
    const provider = { ...this.state.provider, providerTypes };
    this.setState({ provider });
  }

  deleteUser = () => {
    const id = this.state.user.id;
    axios({
      method: 'delete',
      url: url + 'user/' + id,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {

      this.setState({ open: false });
      this.props.getUsers();
    });
  }

  deleteSite = () => {
    const id = this.state.site.id;
    axios({
      method: 'delete',
      url: url + 'site/' + id,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
       this.setState({ open: false });
       this.props.getSites();
    });
  }

  setSite = id => {
    if (id !== undefined) {
      axios({
        method: 'get',
        url: url + 'site/' + id,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      }).then(res => {
        this.setState({ site: res.data, open: true});
      });
    }
  }

  getUser = id => {
    if (id !== undefined) {
      axios({
        method: 'get',
        url: url + 'user/' + id,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      }).then(res => {
       let user = res.data;
        this.setState({ user, open: true });


      });
    }
  }

  submitUser = async user => {
    if ( user.password ) {
      const password = btoa( user.password );
      try {
        await axios({
          method: 'post',
          url: url + 'user?Password=' + password,
          data: user,
          headers: {
            api_key: key,
            AccessToken: getCookie('token'),
          },
        });
        this.props.getUsers();

        return true;
      } catch ( e ) {
        console.log( e.response );
        if ( e.response.status === 409 ) {
          this.setState({ duplicateUsernameModal: true });
        }
        return false;
      };

    } else {
      try {
        const res = await axios({
          method: 'post',
          url: url + 'user',
          data: user,
          headers: {
            api_key: key,
            AccessToken: getCookie('token'),
          },
        });
        if ( res ) {
          this.props.getUsers();

          return true;
        }
      } catch ( e ) {
        if ( e.response.status === 409 ) {
          this.setState({ duplicateUsernameModal: true });
        }
        return false;
      };
    }
  }

  submitSite = async site => {
    try {
      const res = await axios({
        method: 'post',
        url: url + 'site',
        data: site,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      });
      if ( res ) {
        this.props.getSites();
        return true;
      };
    } catch ( e ) {
      if ( e.response.status === 409 ) {
        this.setState({ duplicateSiteNameModal: true });
      }
      return false;
    }
  }

  getNotification = id => {
    if (id !== undefined) {
      axios({
        method: 'get',
        url: url + 'Notification/' + id,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      }).then(res => {
        this.setState({ notification: res.data, open: true });
      });
    }
  }

  submitNotification = notification => {
    axios({
      method: 'post',
      url: url + 'Notification',
      data: notification,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.props.getNotifications();
      this.handleSubmit();
    });
  }

  resetUserPassword = () => {
    let email = this.state.user.email;

      if (email !== undefined) {
        axios({
          method: 'put',
          url: url + 'user/forgotpass?Email=' + email,
          headers: {
            api_key: key,
          },
        }).then(res => {
          this.setState({resetSuccess: res.data });
          // this.setState({resetSuccess: res.data, resetPassNotificationModal: true })
          // this.handleClose()
        });
      }

  }

  render() {
    const {open} = this.state;

    return (
      <React.Fragment>
        <Modal
          trigger={
            <Button color={this.props.color} onClick={this.handleModal}>
              {this.props.buttonText}
            </Button>
          }
          open={open}
          onClose={this.handleClose}
          size="tiny"
          closeOnDocumentClick={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            {this.props.buttonText === 'New User' ? (
              <UserForm
                render={({ newUser }) => (
                  <Form>
                    <Header dividing>
                      <Icon name="user circle" />
                      User Information
                    </Header>
                    {newUser}
                  </Form>
                )}
                siteOptions={this.props.siteOptions}
                handleNewUser={this.handleNewUser}
                handleNewUserPhone={this.handleNewUserPhone}
                user={this.state.newUser}
                userRoleOptions={this.props.userRoleOptions}
              />
            ) : this.props.buttonText === 'New Site' ? (
              <SiteForm
                render={({ newSite }) => (
                  <Form>
                    <Header dividing>
                      <Icon name="building" />
                      Site Information
                    </Header>
                    {newSite}
                  </Form>
                )}
                handleNewSite={this.handleNewSite}
                site={this.state.newSite}
                getSites={this.props.getSites}
              />
            ) : this.props.buttonText === 'New Notification' ? (
              <NotificationForm
                render={({ newNotification }) => (
                  <Form>
                    <Header dividing>Notification Information</Header>
                    {newNotification}
                  </Form>
                )}
                notification={this.state.newNotification}
                handleNewNotification={this.handleNewNotification}
              />
            ) : this.props.siteRow ? (
              <SiteForm
                render={({ editSite }) => (
                  <Form>
                    <Header dividing>
                      <Icon name="building" />
                      Site Information
                    </Header>
                    {editSite}
                  </Form>
                )}
                site={this.state.site}
                handleSiteChange={this.handleSiteChange}
                deleteSite={this.deleteSite}
                getSites={this.props.getSites}
              />
            ) : this.props.userRow ? (
              <UserForm
                render={({ editUser }) => (
                  <Form>
                    <Header dividing>
                      <Icon name="user circle" />
                      User Information
                    </Header>
                    {editUser}
                  </Form>
                )}
                user={this.state.user}
                siteOptions={this.props.siteOptions}
                handleUserChange={this.handleUserChange}
                handleUserPhoneChange={this.handleUserPhoneChange}
                deleteUser={this.deleteUser}
                userRoleOptions={this.props.userRoleOptions}
                resetUserPassword={this.resetUserPassword}
                resetSuccess={this.state.resetSuccess}
              />
            ) : this.props.notificationRow ? (
              <NotificationForm
                render={({ editNotification }) => (
                  <Form>
                    <Header dividing>Edit Notification Information</Header>
                    {editNotification}
                  </Form>
                )}
                notification={this.state.notification}
                handleNotificationChange={this.handleNotificationChange}
              />
            ) : this.props.buttonText === 'New Provider' ? (
              <ProviderForm
                render={({ provider }) => (
                  <Form>
                    <Header dividing>Provider Details</Header>
                    {provider}
                  </Form>
                )}
                provider={this.state.provider}
                handleProviderChange={this.handleProviderChange}
                handleProviderTypeChange={this.handleProviderTypeChange}
                handleClose={this.handleClose}
                getProvider={this.getProvider}
                getProviders={this.props.getProviders}
              />
            ) : this.props.providerRow ? (
              <ProviderForm
                render={({ provider }) => (
                  <Form>
                    <Header dividing>Provider Details</Header>
                    {provider}
                  </Form>
                )}
                provider={this.state.provider}
                handleProviderChange={this.handleProviderChange}
                handleProviderTypeChange={this.handleProviderTypeChange}
                handleClose={this.handleClose}
                getProvider={this.getProvider}
                providerRow={this.props.providerRow}
                getProviders={this.props.getProviders}
                showPinInput
              />
            ) : null}
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleClose}>
              <Icon name="close" /> Cancel
            </Button>
            <Button color="green" onClick={this.handleSubmit}>
              <Icon name="checkmark" /> Confirm
            </Button>
          </Modal.Actions>
          <DuplicateUsernameModal
            open={ this.state.duplicateUsernameModal }
            close= { () => this.setState({ duplicateUsernameModal: false }) }
          />
          <PasswordInstructionsError
            open={ this.state.passwordInstructionsErrorOpen }
            close={ () => this.setState({ passwordInstructionsErrorOpen: false })}
            password={ this.state.newUser.password || '' }
          />
          <RequiredFieldsError
            open={ this.state.userRequiredFieldsErrorOpen }
            close={ () => this.setState({ userRequiredFieldsErrorOpen: false })}
            user={ this.state.user }
          />
          <RequiredFieldsError
            open={ this.state.newUserRequiredFieldsErrorOpen }
            close={ () => this.setState({ newUserRequiredFieldsErrorOpen: false })}
            user={ this.state.newUser }
            checkPass
          />
          <ProviderError
            open={ this.state.providerErrorOpen }
            close={ () => this.setState({ providerErrorOpen: false })}
            provider={ this.state.provider }
          />
          <DuplicateSiteNameModal
            open={ this.state.duplicateSiteNameModal }
            close= { () => this.setState({ duplicateSiteNameModal: false }) }
          />
          <SiteError
            open={ this.state.newSiteErrorOpen }
            close={ () => this.setState({ newSiteErrorOpen: false })}
            site={ this.state.newSite }
          />
          <SiteError
            open={ this.state.siteErrorOpen }
            close={ () => this.setState({ siteErrorOpen: false })}
            site={ this.state.site }
          />
        </Modal>
        {/* <Modal
          open={ this.state.resetPassNotificationModal }
          onClose={ () => this.setState({ resetPassNotificationModal: false }) }
          size="mini"
        >
          <Modal.Content style={{ textAlign: 'center' }}>
            An email containing a with a reset password link has been sent to the user's email address.
          </Modal.Content>
        </Modal> */}
      </React.Fragment>
    );
  }
}
