import React from 'react';
import { List, Modal, Button, Icon } from 'semantic-ui-react';
import { EventsModals } from '../Modals/EventsModals';
import { formatDate } from '../../Util';
import {BaseObjectConsumer} from '../../Context/BaseObjectContext';
import Moment from 'react-moment';

export class ParticipantHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backModal: false
    };
  }

  componentDidMount() {
  }

  navigateToBase = () => {
    if (this.props.activeComponent === 'baseRecord') {
      this.props.setBaseActiveIndex('base', 1);
    } else {
      this.props.setBaseActiveIndex('baseRecord', 1);
    }
  }

  openBackModal = () => {
    this.setState({ backModal: true });
  }

  closeBackModal = () => {
    this.setState({ backModal: false });
  }


  render() {
    const { participantID, notes, smartID, scramblerID, dateRecorded, description } = this.props;
    return (
      <BaseObjectConsumer>
      {({baseObject, addUpdateBaseNotes, updateSmartID}) =>
      <div className="baseHeader">
        <Modal
          open={this.state.backModal}
          onClose={this.closeBackModal}
          size="mini"
        >
          <Modal.Header>
            Are You Sure?
          </Modal.Header>
          <Modal.Content>
            Any unsaved changes will be lost.
            <br />
            Are you sure you'd like to go back?
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={this.navigateToBase}
            >
              Confirm
            </Button>
            <Button
              onClick={this.closeBackModal}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <List
          horizontal
          verticalAlign="middle"
          animated
          size="large"
          relaxed
          style={{ width: '100%' }}
        >
          <List.Item className="first">
            <Button
              content="Back"
              icon="left arrow"
              labelPosition="left"
              onClick={this.props.interfaceScreen ? this.openBackModal : this.navigateToBase}
            />
          </List.Item>
          <List.Item>
            <strong>Smart ID:</strong>
            <EventsModals
              smartID={smartID}
              className="btnSmartID"
              buttonText={ smartID || 'Update' }
              color="transparent"
              updateSmartID={updateSmartID}
              baseType={baseObject.type}
              />
          </List.Item>
          <List.Item>
            <strong>Scrambler ID:</strong> {scramblerID}
          </List.Item>
          <List.Item>
            <strong>Event Date: </strong>
            <Moment format="MM/DD/YYYY">{dateRecorded}</Moment>
          </List.Item>
          <List.Item>
            <strong>Status:</strong> {description}
          </List.Item>
          <List.Item>
            { this.props.showGraph
              ? <EventsModals
                participantID={ this.props.participantID }
                iconButton
                buttonText="Chart"
                color="purple"
                icon="chart line"
                label={null}
                hideActions
              />
              : null
            }
            <EventsModals
              buttonText="Reminders"
              color="purple"
              notes={baseObject.notes}
              icon="sticky note"
              label={{
                as: 'a',
                basic: true,
                content: baseObject.notes.length,
                labelPosition: 'right',
              }}
              addUpdateBaseNotes={addUpdateBaseNotes}
            />
          </List.Item>
        </List>
      </div>
      }
      </BaseObjectConsumer>
    );
  }
}
