import React from 'react';
import {
  Tab,
  Button,
  Responsive,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { EventsSidebar } from '../Sidebar/EventsSidebar';
import { DynamicForm } from '../Forms/DynamicForm';
import { ProgressBar } from './ProgressBar';
import { ParticipantHeader } from '../ParticipantHeader/ParticipantHeader';
import * as _ from 'lodash';
import {
  BaseObjectConsumer
} from '../../Context/BaseObjectContext';
import {
  PointsContextProvider
} from '../../Context/PointsContext';
export class Interface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panesLength: 0,
      activeIndex: 0,
      points: 0,
      eventNote: { value: '' },
      panes: [],
      showPopup: false,
      message: '',
    };
  }

  componentDidMount = () => {
    this.setState({ activeIndex: 0, points: 0 });
    if (this.props.activeComponent === 'interface') {
      this.getPoints(this.props.interfaces);
      this.props.getInterfaces(this.props.interfaceID);
      this.renderAnsweredDynamicSidebar();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.activeIndex === 0 && this.state.totalPoints === undefined) {
      // this.getPoints(this.props.interfaces);
    }

    if (prevState.activeIndex !== this.state.activeIndex) {
      this.renderAnsweredDynamicSidebar();
    }

    if (prevProps.record !== this.props.record) {
      this.getPoints(this.props.interfaces);
      this.renderAnsweredDynamicSidebar();
    }

    if (prevState.points !== this.state.points) {
      this.setState({ points: this.state.points });
    }
  }

  saveTextArea = eventNote => {
    this.setState({ ...this.state.eventNote, eventNote });
  }

  getPoints = ( pointSource = this.props.interfaces ) => {
    if (pointSource !== undefined) {
      // edited by Jonathan 10/02/2019
      let pointsArrays = pointSource.map(uiSet => {//interFace =>
        // interFace.uiSets.map(uiSet =>
          return uiSet.elements.map(element => element.points);
        // )
      });
      // edited by Jonathan 10/02/2019
      let pointElements = pointSource.map(uiSet => {//interFace =>
        // interFace.uiSets.map(uiSet =>
          return uiSet.elements.reduce((acc,element) => element.points > 0 ? [...acc, element ] : acc, [] );
        // )
      });
      pointElements = _.flatMapDeep(pointElements);

      let values = _.flatMapDeep(pointsArrays);
      let filteredValues = _.filter(values, function(o) {
        return o > 0;
      });

      let totalPoints = 0;
      for (let i = 0; i < filteredValues.length; i++) {
        totalPoints += filteredValues[i];
      }

      if (totalPoints > 0) {
        // totalPoints = totalPoints / 2; why would I divide this by 2???
        this.setState({ totalPoints, points: 0 });
      }

      if (
        this.props.activeComponent !== 'newEvent' &&
        this.props.baseObject !== undefined &&
        this.props.inputData !== undefined &&
        this.props.record !== undefined
      ) {
        const inputData = this.props.baseObject.inputData;
        const record = this.props.record.inputData;

        const combined = _.concat(inputData, record);

        let currentPoints = combined.reduce( (acc, value) => {
          if (
            pointElements.some( element => {
              if ( !(element.type == 5 && value.optionID == 0) && !(element.type == 8 && !value.value) ) { // make sure it is not a dropdown with select option selected
                return element.id == value.elementID;
              }
              return false;
            })
          ) {
            return ++acc;
          }
          return acc;
        }, 0);

        this.setState({ points: currentPoints });

        // if (pointSource !== undefined) {
        //   let flat = _.flatMapDeep(pointSource[0]);
        //   let truthy = [];
        //   for (let [key, value] of Object.entries(flat)) {
        //     if (_.isObject(value)) {
        //       let flatElements = _.flatMap(value.elements);
        //       let matches = combined.map(c => c.elementID);
        //       for (let i = 0; i < matches.length; i++) {
        //         _.filter(flatElements, function(o) {
        //           if (matches[i] === o.id && o.points > 0) {
        //             let includes = _.includes(truthy, o);
        //             if (includes == false) {
        //               truthy.push(o);
        //             }
        //           }
        //         });
        //       }
        //     }
        //   }
        //
        //   let filteredPoints = _.filter(truthy, function(o) {
        //     return o.points > 0;
        //   });
        //
        //   if (filteredPoints.length > 0) {
        //     let answeredStatePoints = this.state.points;
        //     filteredPoints = filteredPoints.map(p => p.points);
        //     for (let x = 0; x < filteredPoints.length; x++) {
        //       answeredStatePoints += filteredPoints[x];
        //     }
        //
        //     this.setState({ points: answeredStatePoints });
        //   }
        // }
      }
    }
  }

  setActiveIndex = navigation => {
    switch (navigation) {
      case 'next':
        this.setState({ activeIndex: this.state.activeIndex + 1 });
        break;
      case 'back':
        if (this.state.activeIndex > 0) {
          this.setState({ activeIndex: this.state.activeIndex - 1 });
        }
        break;
      default:
        this.setState({ activeIndex: navigation });
        break;
    }
  }



  renderAnsweredDynamicSidebar() {
    // edited by Jonathan 10/02/2019
    // let uiSets = this.props.interfaces.map(i => i.uiSets);
    let uiSets = [ this.props.interfaces ];
    const iterator = uiSets.values();
    let paneLength = uiSets.map(s => s.length);
    if (this.state.panesLength === 0) {
      paneLength = paneLength[0];
      if (paneLength !== undefined) {
        this.setState({ panesLength: paneLength });
      }
    }
    for (const value of iterator) {
      let panes = value.map(i => ({
        menuItem: {
          key: `${i.id}`,
          content: `${i.label}`,
          style: { color: 'white' },
        },
        render: () => (
          <Tab.Pane attached={true} className="mainForm">
            <BaseObjectConsumer>
              {({
                baseObject,
                inputData,
                points,
                postEventRecord,
                invalidFields,
              }) =>
                baseObject !== undefined ? (
                  <React.Fragment>
                    <PointsContextProvider
                      values={{
                        points: this.state.points,
                        totalPoints: this.state.totalPoints,
                        changedPoints: points,
                      }}
                    >
                      <ParticipantHeader
                        participantID={ baseObject.id}
                        description={baseObject.status.description}
                        smartID={baseObject.smartID}
                        scramblerID={baseObject.scramblerID}
                        notes={baseObject.notes}
                        dateRecorded={baseObject.records[0].dateRecorded}
                        setBaseActiveIndex={this.props.setBaseActiveIndex}
                        updateSmartID={this.props.updateSmartID}
                        updateBaseNotes={this.props.updateBaseNotes}
                        profile={this.props.profile}
                        interfaceScreen={ true }
                      />

                      <ProgressBar />
                      <div>

                      <DynamicForm
                        elements={i.elements}
                        setActiveIndex={this.setActiveIndex}
                        collection={i.isCollection}
                        invalidFields={invalidFields}
                        inputData={inputData}
                        baseObject={baseObject}
                        paneLength={this.state.panesLength}
                        activeIndex={this.state.activeIndex}
                        updateBaseObject={this.props.updateBaseObject}
                        postNewRecord={this.props.postNewRecord}
                        postEventRecord={postEventRecord}
                        activeComponent={this.props.activeComponent}
                        eventNote={this.state.eventNote}
                        saveTextArea={this.saveTextArea}
                        points={points}
                        setBaseActiveIndex={this.props.setBaseActiveIndex}
                        profile={this.props.profile}
                        aiTasks={i.aiTasks}
                        getPoints={this.getPoints}
                      />
                      </div>
                    </PointsContextProvider>
                  </React.Fragment>
                ) : null
              }
            </BaseObjectConsumer>
          </Tab.Pane>
        ),
      }));
      if (this.state.panes !== panes) {
        this.setState({ panes });
      }
      return panes;
    }
  }

  render() {
    return this.props.activeComponent === 'interface' ||
      this.props.activeComponent === 'newEvent' ? (
      <React.Fragment>
        {this.props.baseObject === undefined ||
        this.props.baseObject === null ? (
          <Responsive style={{ height: '300px' }}>
            <Dimmer active size="massive">
              <Loader style={{ marginTop: '10px' }} />
            </Dimmer>
          </Responsive>
        ) : (
          <BaseObjectConsumer>
            {({ baseObject, inputData }) => (
              <React.Fragment>
                <Responsive>
                  <NavigationBar
                    setBaseActiveIndex={this.props.setBaseActiveIndex}
                    profile={this.props.profile}
                  />
                </Responsive>
                <EventsSidebar
                  panes={this.state.panes}
                  activeIndex={this.state.activeIndex}
                  setActiveIndex={this.setActiveIndex}
                  interfaces={this.props.interfaces}
                  inputData={inputData}
                  baseObject={baseObject}
                  postNewRecord={this.props.postNewRecord}
                  activeComponent={this.props.activeComponent}
                  eventNote={this.state.eventNote}
                  saveTextArea={this.saveTextArea}
                  width={this.props.width}
                />
              </React.Fragment>
            )}
          </BaseObjectConsumer>
        )}
      </React.Fragment>
    ) : null;
  }
}

export const NavigationBar = props =>
  props.profile !== undefined ? (
    <Button.Group className="interfaceNavigationBar">
      <Button
        icon="grid layout"
        content="Dashboard"
        className={`interfaceNavigationItem menuDashboard${
          props.profile.role.name
        }`}
        onClick={() => props.setBaseActiveIndex('base', 0)}
      />
      <Button
        icon="medkit"
        content="Events"
        className={`interfaceNavigationItem menuEvents${
          props.profile.role.name
        }`}
        onClick={() => props.setBaseActiveIndex('base', 1)}
      />
      <Button
        icon="chart line"
        content="Reports"
        className={`interfaceNavigationItem menuReports${
          props.profile.role.name
        }`}
        onClick={() => props.setBaseActiveIndex('base', 2)}
      />
    </Button.Group>
  ) : null;
